@import "styles/color.module";
@import "styles/utils.module";


.agreementContainer {
  padding: 20px 16px;
  margin-top: 0;
  background-color: white;
}

.checkbox {
  vertical-align: middle;
}


.agree {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
  line-height: 100%;
  color: $typo3;
  margin-right: 4px;
}


.agreeSeeMore {
  @extend .agree;
  text-decoration: underline;
  color: $blue;
  margin-right: 4px;
}


.agreeAllContainer {
  display: flex;
  flex: 1 1 auto;
}

.agreeTextContainer {
  margin-left: 8px;
}
