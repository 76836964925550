@import "styles/utils.module";
@import "styles/color.module";

.root {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  padding: 0 16px;
  z-index: 100000;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
}

.sectionTitleContainer {
  padding: 0 16px;
}

.calendarContainer {
  margin-top: 24px;
  padding-bottom: 100px;
}

.titleBarContainer {
  width: 100%;
  max-width: $mobileMaxWidth;
  background-color: white;
  @extend .sidePadding;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 0;
  z-index: 1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;

  h2 {
    color: $gray800;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 0px;
  }

  .timeContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: -2.5px;
    margin-right: -2.5px;
    margin-bottom: 40px;
  }

  .timeButtonContainer {
    flex: 0 0 25%;
    padding: 2.5px;
  }
}

.timeCountContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $tableBorder;
  padding: 8px 0;

  &:last-of-type {
    border-bottom: 1px solid $tableBorder;
  }
}

.countSelectorTimeContainer {
  color: $gray900;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.optionListContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  & > * + * {
    margin-top: 8px;
  }
}

.timeCountSelectorListContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  & > * + * {
    padding-top: 8px;
  }
}

.optionCardContainer {
  display: flex;
  border: none;
  padding: 16px;
  border-radius: 5px;
  align-items: center;
  background-color: $gray100;
}

.optionCardName {
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
}

.optionCardDiscountRate {
  color: $brand;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
}

.optionCardName {
  color: $gray800;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.optionCardPriceInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}

.optionCardOriginalPrice {
  color: $gray400;
  font-size: 11px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: line-through;
}

.optionCardDiscountedPrice {
  color: $gray800;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
}

.selectedCardContainer {
  background-color: $brand;
}

.selectedCardText {
  color: white;
}

.button {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray200;
  height: 42px;
  border: none;
  cursor: pointer;

  span {
    color: $gray800;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }
}

.soldOutButton {
  background-color: $gray50;
  cursor: default;

  span {
    color: $gray400;
  }
}

.selectedButton {
  background-color: $gray900;

  span {
    color: white;
  }
}

.bottomContainer {
  position: sticky;
  background-color: white;
  width: 100%;
  bottom: calc(env(safe-area-inset-bottom));
}

.buttonContainer {
  display: flex;
  padding: 12px 16px;

  & > * + * {
    margin-left: 8px;
  }
}

.selectedOptionContainer {
  border-top: 1px solid $tableBorder;
}

.optionContainer {
  margin-bottom: 40px;
}

.personCounterContainer {
  margin-bottom: 100px;
}

.locationName {
  color: $gray800;
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
}

.locationSelectorContainer {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.optionSelectorContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  flex: 1;
  padding: 0 16px 80px 16px;
}

.locationSelectorSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.locationSelectorGrid {
  display: flex;
  flex-wrap: wrap;
}

.locationButton {
  flex: 0 0 calc(50% - 4px);
  margin-top: 8px;

  &:nth-child(odd) {
    margin-right: 8px;
  }
}
