@import "styles/utils.module";
@import "styles/color.module";

.titleContainer {
  @extend .alignItemsCenter;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  margin-bottom: 12px;
}

.noAddress {
  color: $typo3;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
}

.name {
  color: $typo3;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
}

@include tablet {
  .noAddress {
    font-size: 15px;
  }

  .name {
    font-size: 17px;
  }
}

.pipe {
  margin: 0 8px;
  vertical-align: middle;
}

@include tablet {
  .pipe {
    margin: 0 4px;
  }
}

.receiver {
  vertical-align: middle;
  line-height: 100%;
  font-size: 15px;
  color: $typo1;
  display: inline-block;
}

.receiverName {
  font-weight: bold;
  color: $typo3;
  font-size: 15px;
  line-height: 100%;
}

.nameContainer {
  margin-bottom: 12px;
  line-height: 0;
}

.address {
  font-size: 16px;
  line-height: 100%;
  color: $typo3;
  font-weight: 500;
  display: block;
}

@include tablet {
  .nameContainer {
    margin-bottom: 12px;
  }
}

.defaultAddressContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  line-height: 0;
}

.defaultAddressMark {
  color: $brand;
  border-radius: 9999px;
  width: 77px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  border: 1px solid $brand;
  height: 20px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

@include tablet {
  .defaultAddressMark {
    height: 21px;
    font-size: 13px;
    width: 81px;
  }
}

.findAddress {
  background-color: white;
  width: 132px;
  height: 44px;
  border: 1px solid $brand;
  border-radius: 5px;
  color: $brand;
  margin-right: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .cursorPointer;
}

.sameAsOrderer {
  line-height: 100%;
  font-size: 16px;
  font-weight: 500;
  color: $typo3;
  vertical-align: middle;
  display: inline-block;
  margin-left: 8px;
}

.chooseAddress {}

.chooseAddressLabel {}

@include tablet {
  .sameAsOrderer {
    font-size: 14px;
  }
}

@include mobile {
  .chooseAddress {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .chooseAddressText {
    margin-bottom: 0;
  }
}