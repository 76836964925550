@import "styles/color.module";
@import "styles/utils.module";


.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px 20px;
}

.title {
  font-weight: 700;
  font-size: 17px;
  line-height: 140%;
  color: $gray800;
  text-align: center;
}

.subtitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: $gray600;
  text-align: center;
  margin-top: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 8px;
  }
  margin-top: 24px;
  width: 268px;
}
