@import "styles/color.module";
@import "styles/utils.module";


.imageModal {
  position: relative;
  max-width: 800px;
  max-height: calc(100vh - 60px);
}

@include tablet {
  .imageModal {
    max-height: none;
    max-width: calc(100vw - 60px);
  }
}