@import "styles/utils.module";
@import "styles/color.module";


.container {}

@include mobile {
  .container {
    flex-flow: wrap;
  }
}


.button {
  border: 1px solid $tableBorder;
  height: 60px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
  @extend .justifyContentCenter;
  border-radius: 5px;
  overflow: hidden;
  color: $typo2;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  flex: 1;
}

.buttonActive {
  border: 1px solid $brand;
  color: $brand;
  font-weight: bold;
}

.methodContainer {
  max-width: 165px;
  padding-right: 12px;
  width: 25%;
  &:nth-child(4n) {
    padding-right: 0;
  }
}

.compactMethodContainer {
  max-width: none;
  padding-right: 0;
  width: unset;
  flex: 1;
}

@include tablet {
  .button {
    height: 52px;
    font-size: 16px;
  }
}

@include mobile {
  .methodContainer {
    width: 50%;
    margin-top: 10px;
    max-width: 16500px;
    &:nth-child(2n) {
      padding-right: 0;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .compactMethodContainer {
    padding-right: 0;
    &:nth-child(2n+1) {
      padding-right: 5.5px;
    }
    &:nth-child(2n + 2) {
      padding-left: 5.5px;
    }
    //&:nth-child(2) {
    //  margin-top: 10px;
    //}
    flex: none;
    &:first-of-type {
      //width: 100%;
    }
  }

  .oddCompactMethodContainer {
    padding-right: 0;
    &:nth-child(2n) {
      padding-right: 5px;
    }
    &:nth-child(2n + 3) {
      padding-left: 5px;
    }
    &:nth-child(2) {
      margin-top: 10px;
    }
    flex: none;
    &:first-of-type {
      width: 100%;
    }
  }

  .button {
    max-width: none;
    height: 48px;
    font-size: 15px;
  }
}

.kakaoActive {
  & > g > path {
    fill: black;
  }
}

.tossActive {
  & > path {
    fill: #0064FF;
  }
}

.escrowText {
  font-size: 16px;
  line-height: 100%;
}

@include tablet {
  .escrowText {
    font-size: 14px;
  }
}

@include mobile {
  .escrowText {
    font-size: 13px;
  }
}