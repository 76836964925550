@import "styles/utils.module";
@import "styles/color.module";


.keyValueContainer {
  padding: 18px 24px;
  @extend .flexRow;
  @extend .borderUnderLine;
  @extend .alignItemsCenter;
}

.key {
  display: inline-block;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: $typo1;
  width: 200px;
  margin-right: 24px;
}

.value {
  font-size: 14px;
  line-height: 100%;
  display: inline-block;
  font-weight: 500;
  color: $typo3;
  flex: 1;
  white-space: pre-line;
}

@include tablet {
  .key {
    font-size: 13px;
    width: 146px;
    margin-right: 24px;
  }

  .value {
    font-size: 13px;
  }

  .keyValueContainer {
    padding: 16px 24px;
  }
}

@include mobile {
  .key {
    width: 129px;
    margin-right: 20px;
  }

  .keyValueContainer {
    padding: 12px 6px;
  }
}

.subSectionTitle {
  margin-bottom: 20px;
  line-height: 100%;
  font-size: 18px;
  color: $typo3;
  font-weight: bold;
  display: block;
}

@include tablet {
  .subSectionTitle {
    font-size: 16px;
  }
}

@include mobile {
  .subSectionTitle {
    font-size: 15px;
  }
}


.indexContainer {
  display: block;
  padding-top: 8px;
}

@include mobile {
  .indexContainer {
    padding-top: 4px;
  }
}

.listElement {
  display: block;
  line-height: 120%;
  font-weight: 500;
  font-size: 14px;
  color: $typo3;
  margin-top: 8px;
}

@include tablet {
  .listElement {
    font-size: 13px;
  }
}

.subSection {
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
}


@include tablet {
  .subSection {
    padding-left: $layoutLeftPadding;
    padding-right: $layoutRightPadding;
  }
}

@include mobile {
  .subSection {
    margin-bottom: 24px;
  }

  .subSectionTitle {
    margin-bottom: 16px;
  }
}