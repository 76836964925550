@import "styles/utils.module";
@import "styles/color.module";

.subscriptionContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 12px 16px;
  //border-radius: 5px;
  background-color: $lightBackground;
  height: 62px;
}

.subscriptionDescriptionContainer {
  @extend .flexCol;
  justify-content: space-between;
  & > * + * {
    margin-top: 4px;
  }

  flex: 1;
}

.subscriptionText {
  display: block;
  flex: 1;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  color: $gray800;

  span {
    color: $brand;
  }
}

.subscriptionSubtext {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: $gray500;
}

.subscriptionButton {
  width: 85px;
  height: 30px;
  background-color: $brand;
  color: white;
  line-height: 100%;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.subscribedButton {
  background-color: $gray200;
  color: $gray500;
}


.pointIcon {
  width: 100%;
  height: 100%;
}

.pointIconWrapper {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.bottomPointIconWrapper {
  width: 40px;
  position: relative;
  height: 40px;
  margin-right: 8px;
}
