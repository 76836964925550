@import "styles/utils.module";
@import "styles/color.module";


.titleBarContainer {
  width: 100%;
  max-width: $mobileMaxWidth;
  @extend .sidePadding;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 0;
  z-index: 1;
}
