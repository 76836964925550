@import "styles/utils.module";
@import "styles/color.module";

.addButtonContainer {
  background-color: $brand;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  border-radius: 9999px;
  width: 36px;
  height: 36px;
  margin: 6px;
  @extend .cursorPointer;
  transition:width 0.2s;
}

.addButtonContainerExtended {
  width: 98px;
}

.addButtonText {
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: white;
  margin-right: 4px;
  flex: 0;
  word-break: keep-all;
}
