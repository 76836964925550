@import "styles/utils.module";
@import "styles/color.module";


.totalPointContainerElements {
  padding-bottom: 6px;
  @extend .cursorPointer;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}

.totalPointContainer {
  padding-top: 20px;
  padding-bottom: 24px;
  @extend .flexCol;
}

.totalPointArrow {
  width: 18px;
  height: 18px;
  & path {
    fill: #000;
  }
}

.totalPointWrapper {
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.pointIcon {
  width: 100%;
  height: 100%;
}

.pointIconWrapper {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.bottomPointIconWrapper {
  width: 30px;
  position: relative;
  height: 30px;
  margin-right: 8px;
}

.totalPoints {
  font-weight: bold;
  line-height: 100%;
  font-size: 18px;
  color: $gray800;
  margin-right: 8px;
}

.rewardExpires {
  font-weight: 500;
  font-size: 12px;
  line-height: 16.8px;
  color: $gray500;
}

.gainablePoints {
  font-weight: 600;
  line-height: 100%;
  font-size: 13px;
  color: #fff;
  background-color: $brand;
  padding: 5px 12px;
  border-radius: 9999px;
}

.rewardCardWrapper {
  margin-bottom: 20px;
}

.rewardCardContainer {
  @extend .cursorPointer;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.rewardImageWrapper {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 9999px;
  overflow: hidden;
  background-color: $lightBackground;
}

.rewardIcon {
  width: 100%;
  height: 100%;
}

.rewardInfoWrapper {
  @extend .fullFlex;
  margin-left: 16px;
}

.rewardCardTitle {
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  color: $gray800;
  display: block;
}

.rewardCardDescription {
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: $brand;
}

.rewardReceived {
  border-radius: 9999px;
  background-color: $gray200;
  width: 47px;
  height: 23px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  color: $gray500;
}

.rewardImageOverlay {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.subscriptionContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 7px 12px;
  border-radius: 5px;
  background-color: $lightBackground;
  height: 54px;
}

.subscriptionDescriptionContainer {
  @extend .flexCol;
  justify-content: space-between;
  & > *+* {
    margin-top: 2px;
  }
  flex: 1;
}

.subscriptionText {
  display: block;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color: $gray800;

  span {
    color: $brand;
  }
}

.subscriptionSubtext {
  flex: 1;
  font-size: 11px;
  font-weight: 500;
  line-height: 120%;
  color: $gray500;
}

.subscriptionButton {
  width: 85px;
  height: 30px;
  background-color: $brand;
  color: white;
  line-height: 100%;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.subscribedButton {
  background-color: $gray200;
  color: $gray500;
}

.offerwallAgreeModalContainer {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 32px 16px 16px;
  & path:first-of-type {
    fill: #FFA0CC;
    stroke: #FFA0CC;
  }
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.agreeModalTitle {
  margin-top: 10px;
  font-weight: bold;
  line-height: 140%;
  font-size: 20px;
  color: $gray800;
}

.agreeModalSubtitle {
  margin-top: 8px;
  font-weight: 500;
  line-height: 120%;
  font-size: 14px;
  color: $gray500;
  text-align: center;
  margin-bottom: 32px;
}

.agreeContentLink {
  margin-bottom: 16px;
  border-radius: 5px;
  padding: 0 12px;
  height: 40px;
  background-color: $gray100;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray500;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  & path {
    fill: $gray500 !important;
    stroke: none !important;
  }
}

.agreeButtonContainer {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  width: 100%;
  & > *+* {
    margin-left: 8px;
  }
}
