@import "styles/color.module";
@import "styles/utils.module";

.cropModalDoneButton {
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: $brand;
  @extend .cursorPointer;
}

.cropModalLoadingText {
  text-align: center;
  font-size: 15px;
  color: white;
  font-weight: 500;
  line-height: 130%;
  display: block;
  margin-top: 12px;
}

.cropModalContainer {
  height: 100%;
  background-color: $typo4;
  position: relative;
}

.cropModalLoadingScreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($typo4, 0.75);
  z-index: 1000000;
  @extend .flexCol;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
}

.cropModalLoadingCount {
  color: white;
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  margin-top: 4px;
  display: block;
}

.cropModalTitleBar {
  background-color: $typo4;
}

.cropModalCloseButton {
  & path {
    fill: white;
  }
  @extend .cursorPointer;
}

.cropModalPreviewContainer {
  display:flex;
  width:100%;
  max-width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 20px;
}

.cropModalPreviewContainer::-webkit-scrollbar {
  display: none;
}

.cropModalPreviewWrapper2 {
  width: 100%;
  height: 100%;
  //position: relative;
  & > img {
    pointer-events: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  overflow: hidden;
  //position: relative !important;
  border-radius: 5px;
}

.cropModalPreviewWrapper3 {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.cropModalPreviewWrapper {
  flex: 0 0 86px;
  padding-right: 10px;
  @extend .noLineHeight;
  &:last-of-type {
    flex: 0 0 96px;
    padding-right: 20px;
  }
  min-width: 76px;
  height: 76px;
  //background-color: red;
  @extend .cursorPointer;
}

.cropModalPreviewOverlay {
  background-color: rgba($typo4, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cropperClassName {
  & .dashed-h {
    opacity: 1 !important;
    border-color: red;
  }

  & .dashed-v {
    opacity: 1 !important;
    border-color: red;
  }

  & :global(span.cropper-dashed) {
    opacity: 1 !important;
  }
}

.cropperWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.cropperWrapperHidden {
  display: none;
}

.cropModalSpeechBubbleContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .noLineHeight;
  padding: 4px 8px 5px 12px;
}

.cropModalSpeechBubbleText {
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
}

.cropModalSpeechBubbleButton {
  width: 16px;
  height: 16px;
  margin-left: 2px;
  @extend .cursorPointer;
  & path {
    fill: white;
  }
}