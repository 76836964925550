@import "styles/color.module";
@import "styles/utils.module";


.body {
  width: 300px;
  background: white;
  border-radius: 5px;
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: $typo4;
  padding: 24px 12px;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  text-align: center;
  border-bottom: 1px solid $border;
  //word-break: keep-all;
  white-space: pre-wrap;
}

.buttonWrapper {
  @extend .flexRow;
  justify-content: space-between;
}

.cancelButton {
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: $typo4;
  padding: 16px 12px;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  flex: 1;
}

.confirmButton {
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #FF5B59;
  padding: 16px 12px;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  border-left: 1px solid $border;
  flex: 1;
}

.highlight {
  color: $brand;
}
