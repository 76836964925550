@import "styles/color.module";
@import "styles/utils.module";

.root {
  display: flex;
  border: 1px solid $brand;
  border-radius: 5px;
  cursor: pointer;
}

.grayBorder {
  border-color: $gray300;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 16px;
}

.title {
  display: flex;
  & > *+* {
    margin-left: 6px;
  }
  align-items: center;
}
.tagsContainer {
  margin-bottom: 8px;
}

.inactiveTagsContainer {
  opacity: 50%;
}

.appOnlyTag {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: $brand;
  background-color: black;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 3px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.giftOnlyLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: $gray800;
  background-color: $gray200;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 3px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.duplicateAllowedLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: $gray500;
  background-color: $gray200;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 3px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.inactiveAppOnlyTag {
  background-color: $gray200;
}

.discountAmount {
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: $brand;
}

.discountAmountInactive {
  @extend .discountAmount;
  color: $gray500;
}

.descriptionContainer {
  display: flex;
  margin-top: 8px;
}

.conditionText {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $gray500;
  margin-top: 8px;
}

.brandCoupon {
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  color: $gray800;
}

.description {
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  color: $gray800;
}

.expiresAt {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $gray400;
  margin-top: 8px;
}

.expiresAtWithCondition {
  margin-top: 4px;
}

.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand;
  width: 54px;
}

.downloadButtonV2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.hasDownloadedRoot {

}

.inactiveRoot {
  border-color: $gray200;
  cursor: default;
}

.inactiveTitle {
  color: $disabled;
}

.inactiveDescription {
  color: $gray400;
}

.inactiveConditionText {
  color: $gray400;
}

.inactiveText {
  color: $gray400;
}

.downloadButtonInactive {
  background-color: $gray200;
}

.downloadIcon {
  width: 24px;
  height: 24px;
}

.checkIcon {
  width: 24px;
  height: 24px;
}

.inactiveDownloadIcon {
  & > path {
    stroke: $gray500;
  }
}
