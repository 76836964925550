@import "styles/color.module";
@import "styles/utils.module";


.container {
  top: 0;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  background-color: rgba($typo4, 0.4);
  z-index: 100000;
  flex-direction: column;
  @extend .flexCol;
  @extend .alignItemsCenter;
  @extend .justifyContentEnd;
  max-width: $mobileMaxWidth;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  transition: background-color 0.3s linear;
}

.contentContainer {
  width: 100%;
  transition: transform 0.3s ease-out;
  padding-bottom: calc(env(safe-area-inset-bottom));
  //transition-delay: s;
}
