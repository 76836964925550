@import "styles/color.module";
@import "styles/utils.module";


.container {
  display: block;
  line-height: 0;
}

.wrapper {
  line-height: 0;
  @extend .flexRow;
  align-items: flex-end;
}

.rate {
  font-size: 24px;
  line-height: 100%;
  color: $typo3;
  margin-right: 4px;
  display: inline-block;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 2px;
}

.five {
  color: $typo1;
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  display: inline-block;
  //vertical-align: middle;
}

.leftMargin {
  margin-left: 4px;
}

.scoreText {
  font-weight: bold;
  line-height: 100%;
  color: $brand;
  vertical-align: middle;
  margin-left: 2px;
  font-size: 13px;
}

.star {
  vertical-align: middle;
}

@include tablet {
  .star {
    width: 14px;
    height: 14px;
  }
}
