@import "styles/utils.module";
@import "styles/color.module";

.container {
  max-height: 720px;
  overflow-y: auto;
}

.title {
  padding: 24px 16px 3px 16px;
}

.modalBackground {
  background: rgba($typo4, 0.4);
  //height: 100%;
  //max-height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
}

.footer {
  background-color: white;
  padding-bottom: 34px;
  position: sticky;
  left:0;
  bottom: 0;
  z-index: 999;
}

.purchaseSectionContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 6px 16px calc(env(safe-area-inset-bottom) + 6px) 16px;
}

.purchaseScrapIcon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.purchaseButtonWrapper {
  flex: 1;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0;
  }
}

.optionInfoSectionContainer {
  padding: 20px 16px;
}

.optionFailureContainer {
  margin-top: 146px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @extend .flexRow;
}

/****** OPTION INFO SECTION ******/
.headContainer {
  @extend .flexRow;
}

.optionImageWrapper {
  width: 62px;
  height: 62px;
  position: relative;
  margin-right: 28px;
  overflow: hidden;
}

.nameContainer {
  flex: 1;
}

.brandName {
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $typo3;
  margin-bottom: 12px;
  display: block;
}

.productName {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  margin-bottom: 8px;
  display: block;
}

.pipe {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  @extend .verticalMiddle;
}

.optionText {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  @extend .verticalMiddle;
}

.priceContainer {
  margin-top: 24px;
}

.priceRow {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.deliveryFee {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  display: block;
  width: 90px;
  text-align: right;
}

.price {
  font-size: 15px;
  font-weight: bold;
  line-height: 100%;
  color: $typo3;
  width: 80px;
  text-align: right;
}

.lowPrice {
  color: $brand;
}

.priceTitle {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  color: $typo2;
  line-height: 100%;
  position: relative;
}

.myLowestPriceHelp {
  vertical-align: middle;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  @extend .cursorPointer;
}

.notExistsProductOptionHelp {
  width: 40px;
  height: 40px;
  & path {
    fill: $brand;
  }
  @extend .cursorPointer;
}

.helperAlert {
  position: absolute;
  left: 74px;
  top: 20px;
  color: white;
  background-color: #21272A;
  padding: 8px 12px;
  border-radius: 5px;
  width: 210px;
  z-index: 10;
  @extend .flexRow;
}

.helperAlertText {
  color: white;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  flex: 1;
}

.helperAlertTextCloseButton {
  & path {
    fill: white;
  }
  width: 20px;
  height: 20px;
  margin-left: 8px;
  @extend .cursorPointer;
}

@include mobile {
  .myLowestPriceHelp {
    margin-left: 5px;
    width: 12px;
    height: 12px;
  }

  .helperAlertText {
    font-size: 13px;
  }

  .helperAlertTextCloseButton {
    width: 16px;
    height: 16px;
  }
}

.lowPriceTitle {
  font-weight: bold;
  color: $brand;
}

/***** HEMEKOMENT *****/

.sectionTitle {
  font-size: 17px;
  font-weight: bold;
  color: $typo4;
  line-height: 100%;
  vertical-align: middle;
}

.hemekomentSection {
  padding: 20px 16px 0 16px;
}

.bulletTextWrapper {
  margin-top: 0;
  &:first-of-type {
    margin-top: 20px;
  }
}


/**** REVIEW SECTION ****/
.reviewTitleContainer {
  padding: 16px 16px 16px 16px;
}

.reviewCount {
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $gray400;
  margin-left: 4px;
  @extend .cursorPointer;
}

.rightArrow {
  vertical-align: middle;
  width: 13px;
  height: 13px;
}

.optionRightArrow {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.optionRightArrowWrapper {
  @extend .alignItemsCenter;
}

/*** REVIEW CARD ***/
.reviewCardContainer {
  padding: 20px 0;
  border-bottom: 1px solid $border;
}

.optionName {
  font-size: 13px;
  font-weight: 500;
  color: $typo2;
  line-height: 100%;
  margin-top: 8px;
  display: block;
}

.reviewText {
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
  color: $typo3;
  margin-top: 12px;
  display: block;
  white-space: pre-wrap;
  word-break: break-all;
}

.reviewImageContainer {
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.reviewHorizontalScrollElement {
  flex: 0 0 80px;
  display: inline-block;
  margin-left: 4px;
  &:first-of-type {
    margin-left: 16px;
  }
}

.reviewImageWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.mallReviewCustomerName {
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  color: $gray600;
  margin-left: 9px;
  margin-top: 2px;
}

.notExistsProductName {
  font-size: 15px;
  line-height: 21px;
  margin-top: 12px;
  font-weight: 500;
  color: $typo3;
}

.bottomSheetContainer {
  & > div[data-rsbs-backdrop="true"] {
    //z-index: 1000000000;
    z-index: 100000;
  }

  & > div[aria-modal="true"] > div[data-rsbs-scroll="true"] {
    //z-index: 1000000000;
    border-radius: 16px !important;
  }

  & > div[aria-modal="true"] {
    //z-index: 1000000000;
    z-index: 100000;
    max-width: $mobileMaxWidth;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
  }
}