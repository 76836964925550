@import "styles/color.module";
@import "styles/utils.module";


.countryInputSelectorContainer {
  width: 100%;
  margin-top: 20px;
}


.bottomButtonContainer {
  @extend .sidePadding;
  background-color: white;
  max-width: $mobileMaxWidth;
  width: 100%;
  z-index: 10001;
  padding: 6px 0 16px;
  bottom: calc(env(safe-area-inset-bottom) + $mobilNavBarHeight) !important;
  transition: bottom 0.1s ease;
  margin-top: 32px;
}

.selectCountryModalContainer {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  background: linear-gradient(163deg, #1A1A1A 56.08%, #FFABB4 218.61%);
}

.selectCountryModalContentContainer {
  background-color: white;
  background-size: contain;
  width: 100%;
  padding: 32px 16px env(safe-area-inset-bottom) 16px;
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.selectCountryModalTitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: $gray800;
  text-align: center;
  padding: 0 11px;
}

.cancelButton {
  width: 41%;
  min-width: 138px;
}

.saveButton {
  width: 59%;
  min-width: 197px;
  margin-left: 8px;
}
