@import "styles/color.module";
@import "styles/utils.module";


.container {
  margin: 40px 0 32px 0;
}

@include mobile {
  .container {
    margin: 0;
  }
}

.checkboxContainer {
  line-height: 0;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @extend .cursorPointer;
}

.checkbox {
  vertical-align: middle;
  margin-right: 8px;
}

.agreeAllContainer {
  margin-bottom: 0;
}

.agreeText {
  vertical-align: middle;
  line-height: 100%;
  color: $typo2;
  font-weight: 500;
  font-size: 14px;
}

@include mobile {
  .agreeText {
    font-size: 13px;
  }

  .agreeAllText {
    font-size: 15px;
  }

  .seeMore {
    font-size: 13px;
  }
}

.agreeAllText {
  line-height: 120%;
  font-weight: bold;
  font-size: 16px;
  color: $typo2;
}

.border {
  width: 100%;
  height: 1px;
  background-color: $border;
  margin: 24px 0;
}

.seeMore {
  margin-left: 12px;
  color: $typo1;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: underline;
}