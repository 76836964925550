@import "styles/color.module";
@import "styles/utils.module";


.scoreCountRowScoreContainer {
  width: 19px;
  @extend .flexRow;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
}

.scoreCountRowScore {
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #424242;
  text-align: center;
}

.scoreCountRowCount {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $typo2;
  margin-left: 8px;
}

.scoreCountRow {
  @extend .flexRow;
  align-items: center;
  margin-top: 6px;
  &:first-of-type {
    margin-top: 0;
  }
}

.scoreCountPercentage {
  background-color: $brand;
  border-radius: 9999px;
  height: 4px;
}

.backgroundRow {
  background-color: $border;
  border-radius: 9999px;
  width: 100px;
  height: 4px;
}
