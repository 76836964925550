@import "styles/color.module";
@import "styles/utils.module";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 24px 48px 24px;
  background-color: black;
  border-radius: 10px;
  position: relative;
}

.rootHtml {
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
  padding: 0;
}

.closeIconWrapper {
  position: absolute;
  top: 16px;
  right: 16px;
}

.closeIcon {
  width: 24px;
  height: 24px;

  & > path {
    fill: white;
  }
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  color: white;
  text-align: center;
  word-break: keep-all;
}

.description {
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: white;
  text-align: center;
}

.couponList {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 16px;
  }
  width: 100%;
}

.footer {
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: white;
}

.hideContainer {
  position: absolute;
  margin-top: 12px;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-decoration-line: underline;
  width: 100%;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
}

.pipeIcon {
  margin: 0 8px;
}

.coupon {
  padding: 20px 20px 16px 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.couponInfo {
  display: flex;
  flex-direction: column;
}

.couponName {
  font-size: 14px;
  margin-bottom: 8px;
  color: $gray800;
}

.couponTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: $gray800;
  margin-bottom: 12px;
}

.couponDescriptionContainer {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 6px;
  }
}

.couponDescription {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: $gray800;
}

.couponDownload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalButtonWrapper {
  padding: 0 24px 24px;
  width: 100%;
}
