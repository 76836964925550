@import "styles/color.module";
@import "styles/utils.module";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 8px;
  }
}

.extraOptionSection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 12px;
  }
}

.extraOptionText {
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
  font-weight: 500;
}
