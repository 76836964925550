@import "styles/utils.module";
@import "styles/color.module";


.container {
  @extend .flexCol;
  @extend .alignItemsCenter;
  text-align: center;
  padding: 160px;
}

.title {
  margin-top: 40px;
  display: block;
  font-size: 28px;
  font-weight: bold;
  line-height: 100%;
  color: $brand;
}

.description {
  margin-top: 20px;
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  color: $typo3;
}

.icon {
}

@include tablet {
  .container {
    padding: 70px;
  }
  .title {
    margin-top: 20px;
    font-size: 22px;
  }
  .description {
    font-size: 16px;
  }
}

@include mobile {
  .container {
    padding: 50px 30px 30px;
  }
  .title {
    margin-top: 20px;
    font-size: 20px;
  }
  .description {
    font-size: 14px;
  }
}