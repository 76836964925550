@import "styles/color.module";
@import "styles/utils.module";

.body {
  width: 310px;
  background: white;
  border-radius: 10px;
  padding: 20px 0 6px 0;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $gray800;
  padding: 0 20px;
}

.description {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: $gray600;
  margin-top: 12px;
  padding: 0 20px;
}

.buttonWrapper {
  @extend .flexRow;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 20px 10px 20px;
  & > *+* {
    margin-left: 10px;
  }
}

.cancelButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: $brand;
}

.confirmButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: $brand;
}
