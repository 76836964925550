@import "styles/color.module";
@import "styles/utils.module";

.container {
  background-color: white;
  border-top: 1px solid $border;
  z-index: 10000;
  @extend .fullWidth;
  @extend .alignItemsCenter;
  @extend .flexRow;
}


.content {
  height: 300px;
  max-width: 1256px;
  margin: auto;
  @extend .alignItemsCenter;
  @extend .justifyContentSpaceBetween;
  padding: 0 60px;
  flex: 1;
}

@include tablet {
  .container {
  }

  .content {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 24px 20px;
  }
}

@include mobile  {
  .content {
    padding-bottom: 80px;
  }
}

.listSection {
  margin-top: 20px;
  flex: 1;
}

@include tablet {
  .listSection {
    margin-top: 0;
  }
}

.customerCenter {
  display: inline-block;
  font-size: 16px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
  margin-left: 3px;
  vertical-align: middle;
}

.customerCenterNumber {
  display: block;
  font-size: 28px;
  font-weight: bold;
  line-height: 28px;
  color: $typo4;
  margin-bottom: 14px;
}

@include tablet {
  .customerCenter {
    font-size: 15px;
  }

  .customerCenterNumber {
    margin-bottom: 12px;
  }
}

.leftContainer {
  margin-right: 180px;
}

.CSTime {
  display: block;
  font-size: 13px;
  line-height: 130%;
  font-weight: 500;
  color: $typo2;
  margin-top: 8px;
}

@include tablet {
  .CSTime {
    font-size: 12px;
  }
}

.li {
  line-height: 140%;
  font-size: 12px;
  color: $typo3;
  font-weight: 500;
  white-space: nowrap;
  @extend .cursorPointer;
  margin-top: 4px;
}

.bold {
  font-weight: bold;
}

.li:not(:last-child) {
  margin-right: 16px;
}

.infoContainer {
  & > * + * {
    margin-top: 2px;
  }
}

.info {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: $typo2;
}

.iconsSection {
  margin-top: 40px;
  @extend .alignItemsCenter;
}

.bigTitles {
  @extend .alignItemsCenter;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 12px;
}

@include tablet {
  .leftContainer {
    width: 100%;
  }

  .rightSection {
    width: 100%;
    text-align: left;
    margin-top: 40px;
  }

  .iconsSection {
    margin-top: 32px;
  }
}

@include mobile {
  .rightSection {
    margin-top: 32px;
  }
}


.icon {
  width: 28px;
  height: 28px;
  margin-right: 24px;
}

@include tablet {
  .icon {
    width: 28px;
    height: 28px;
  }
}

@include mobile {
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
}

.globalMall {
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.globalText {
  font-weight: 700;
  font-size: 12px;
  color: $typo3;
  margin-left: 2px;
  line-height: 140%;
}
