@import "styles/color.module";
@import "styles/utils.module";


.modalTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
}

.modalContainer {
  padding: 0 24px 40px 24px;
  max-height: 718px;
  min-height: 718px;
  height: 718px;
}

@include mobile {
  .modalContainer {
    height: 100%;
    min-height: 0;
    max-height: 100%;
    padding: 0 20px;
  }
}

.titleContainer {
  @extend .justifyContentSpaceBetween;
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 37px 0;
}


.cardContainer {
  border: 1px solid $tableBorder;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  @extend .flexCol;
  @extend .alignItemsStart;
}

.cardContainerDesktop {
  border: none;
  border-radius: 0;
  padding: 24px;
  flex-direction: row !important;
}

.infoContainer {
  flex: 1;
  width: 100%;
  @extend .flexCol;
}

.infoContainerDesktop {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
}

.nameContainer {
  @extend .justifyContentSpaceBetween;
  @extend .flexRow;
  @extend .alignItemsStart;
  margin-bottom: 20px;
}

.addressName {
  color: $typo3;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: inline-block;
}

.actionButton {
  color: $brand;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  text-decoration: underline;
  margin-right: 12px;
  @extend .cursorPointer;
  &:last-of-type {
    margin-right: 0;
  }
}

.pipe {
  vertical-align: middle;
}

.contact {
  vertical-align: middle;
  line-height: 100%;
  font-size: 15px;
  font-weight: 500;
  color: $typo2;
}

.contactContainer {
  line-height: 0;
}

.address {
  font-size: 16px;
  color: $typo3;
  font-weight: 500;
  line-height: 120%;
  display: block;
  margin-top: 12px;
}


@include mobile {
  .contact {
    font-size: 14px;
  }
}

.addButton {
  margin-top: 20px;
  margin-bottom: 12px;

}

.addText {
  vertical-align: middle;
  line-height: 100%;
  display: inline-block;
  margin-right: 4px;
  color: $brand;
  font-weight: bold;
  font-size: 15px;
}

.findAddressButton {
  width: 132px;
  height: 44px;
  margin-left: 6px;
  border: 1px solid $brand;
  border-radius: 5px;
  color: $brand;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .cursorPointer;
}


.defaultAddress {
  margin-left: 8px;
  color: $typo3;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  vertical-align: middle;
}

.defaultAddressContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  line-height: 0;
}

.defaultAddressMark {
  color: $brand;
  border-radius: 9999px;
  width: 77px;
  margin-left: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  border: 1px solid $brand;
  height: 20px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

.editContainer {
  margin-top: 20px;
}

.selectButton {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 57px;
}

.memo {
  margin-top: 12px;
  display: block;
  font-size: 12px;
  line-height: 100%;
  color: $typo1;
  font-weight: 500;
}

.memoContainer {
  margin-top: 8px;
}

.addressCardWrapper {
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 60px;
  }
}

.saveButtonWrapper {
  padding: 12px 0 calc(env(safe-area-inset-bottom) + 12px) 0;
}
