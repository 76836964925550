@import "styles/utils.module";
@import "styles/color.module";

.floatingContainer {
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.floatingWrapper {
  position: relative;
  max-width: $mobileMaxWidth;
  left: 50%;
  transform: translate(-50%, 0);
}

.floatingSection {
  position: absolute;
  right: 14px;
  bottom: calc(env(safe-area-inset-bottom) + 72px);
  text-align: right;
}

.feedbackButtonContainer {
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  height: 28px;
  background-color: $typo4;
  border-radius: 9999px;
  padding: 0 12px;
  @extend .cursorPointer;
  margin-top: 12px;
}

.highFloatingSection {
  bottom: calc(env(safe-area-inset-bottom) + 132px);
}

.feedbackText {
  color: white;
  display: block;
  margin-left: 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
}

.scrollTopButtonWrapper {
  @extend .cursorPointer;
}

.toastCloseIcon {
  & > path {
    fill: white;
  }
}

.appModalContainer {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  background: linear-gradient(163deg, #1A1A1A 56.08%, #FFABB4 218.61%);
}

.appModalCloseIcon {
  top: 16px;
  right: 16px;
  position: absolute;
}

.appModalCopyContainer {
  flex: 122px;
  padding: 0;
  border: none;
  background-color: rgba(0,0,0,0);
}

.appModalContentContainer {
  background-image: url(https://d1cnx04b8cgzcv.cloudfront.net/media/modal/app_modal_background.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 10px 16px 8px;
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.appModalCouponIcon {
    width: 150px;
    height: 150px;
    background-image: url(https://d1cnx04b8cgzcv.cloudfront.net/media/modal/app_modal_coupon_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.appModalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  color: white;
  margin-top: -11px;
  text-align: center;
}

.appModalDescription {
  margin-top: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  color: $gray400;
  text-align: center;
}

.inputButtonContainer {
  display: flex;
  align-items: center;
  & > *+* {
    margin-left: 8px;
  }
  margin: 20px 0 10px;
  flex: 1;
  width: 100%;
}

.copyButtonWrapper {
  width: 122px;
}

.appModalButtonWrapper {
  width: 100%;
}

.appModalInput {
  background-color: white;
  height: 46px;
  border: 1px solid $gray300;
  width: 100%;
  border-radius: 5px;
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  font-size: 14px;
  line-height: 100%;
  font-weight: 700;
  color: $gray800;
}

.appModalHideToday {
  font-size: 13px;
  line-height: 140%;
  color: $gray600;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 8px;
}

//.appModalContainer {
//  padding: 32px 27px calc(env(safe-area-inset-bottom) + 24px);
//  position: relative;
//  @extend .flexCol;
//  align-items: center;
//}
//
//.appModalText {
//  display: block;
//  margin-top: -13px;
//  font-weight: 700;
//  font-size: 20px;
//  line-height: 140%;
//  text-align: center;
//  color: $gray800;
//}
//
//.appModalDescription {
//  margin-top: 8px;
//  font-size: 12px;
//  line-height: 140%;
//  text-align: center;
//  color: $gray500;
//  margin-bottom: 20px;
//}
//
//.appModalSeeNoMore {
//  display: block;
//  margin-top: 12px;
//  line-height: 140%;
//  font-size: 12px;
//  color: $gray400;
//  font-weight: 600;
//  @extend .cursorPointer;
//}
//
//.appModalCloseButton {
//  position: absolute;
//  top: 16px;
//  right: 16px;
//  @extend .cursorPointer;
//  & path {
//    fill: black;
//  }
//}
//
//.appModalButtonWrapper {
//  @extend .fullWidth;
//  @extend .block;
//}
//
//.appModalPriceTagWrapper {
//  width: 170px;
//}

.pushModalContainer {
  padding: 32px 27px calc(env(safe-area-inset-bottom) + 24px);
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.pushModalTitle {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: $gray800;
  line-height: 140%;
  margin-bottom: 8px;
}

.pushModalContent {
  text-align: center;
  font-weight: 500;
  line-height: 140%;
  font-size: 14px;
  color: $gray800;
  display: block;
  margin-bottom: 16px;
}

.pushModalImage {
  width: 151px;
  margin-bottom: 20px;
}

.pushModalButton {
  width: 100%;
  margin-bottom: 12px;
}

.pushModalSkip {
  @extend .cursorPointer;
  font-size: 12px;
  line-height: 140%;
  font-weight: 600;
  color: $gray400;
}

.refreshControllerContainer {
  position: fixed;
  top: 0;
  height: 0;
  left: 0;
  width: 100%;
  right: 0;
  z-index: 100002;
}

.refreshControllerWrapper {
  position: absolute;
  bottom: 0;
  transform: translateY(-40px);
  left: 0;
  right: 0;
  @extend .flexRow;
  @extend .justifyContentCenter;
}

.refreshController {
  width: 42px;
  height: 42px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  @extend .flexRow;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
}
