.react-calendar {
    max-width: 100%;
    font-family: 'Arial', 'Helvetica', sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

}
.react-calendar__navigation {
    display: flex;
    justify-content: center;
    /*height: 44px;*/
    margin-bottom: 24px;
    align-items: center;
}

.react-calendar__navigation__label {
    flex-grow: inherit !important;
    color: #424242 !important;
    font-weight: 500 !important;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none !important;
}

.react-calendar__navigation button:disabled {
    /*background-color: #f0f0f0;*/
    background: none !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    /*background-color: #e6e6e6;*/
    background: none !important;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500 !important;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;

    > * {
        text-decoration: none;
    }
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575 !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 15px 6.6667px;
    background: none;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #424242 !important;
}

.react-calendar__tile:disabled {
    /*background-color: #f0f0f0;*/
    background: none;
    color: #e0e0e0 !important;
    font-weight: 500 !important;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #e0e0e0 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: none;
}

.react-calendar__tile--now {
    background: none;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: none;
}

.react-calendar__tile--hasActive {
    background: none;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    /*background: #a9d4ff;*/
}

.react-calendar__tile--active {
    /*background: #FFA0CC;*/
    background: none;
    /*border-radius: 9999px;*/
    color: white !important;
    position: relative;
    > abbr {
        color: white;
        z-index: 2;
        position: relative;
    }
}

.react-calendar__tile--active::after {
    content: '';
    display: block;
    width: 30px; /* Adjust as needed */
    height: 30px; /* Adjust as needed */
    border-radius: 50%;
    background: #FFA0CC !important;
    /*border: 2px solid #1976d2; !* Circle color *!*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    /*background: #1087ff;*/
}

.react-calendar--selectRange .react-calendar__tile--hover {
    /*background-color: #e6e6e6;*/
}

.react-calendar__month-view__days__day--weekend {
    color: #ff5b59 !important;
}


.react-calendar__navigation__label__labelText {
    font-weight: 500 !important;
    font-size: 14px !important;
}
