@import "styles/color.module";
@import "styles/utils.module";


.toggleContainer {
  border: 1px solid $gray300;
  background-color: $gray300;
  border-radius: 9999px;
  width: 42px;
  padding-left: 0;
  @extend .cursorPointer;
  transition: padding-left 0.1s ease-in;
}

.toggleContainerOn {
  padding-left: 20px;
  background-color: $brand;
  border-color: $brand;
}

.toggleButton {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 9999px;
}
