@import "styles/utils.module";
@import "styles/color.module";


.container {
  padding: 24px 16px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  border-bottom: 1px solid $border;
}

@include mobile {
  .container {
    flex-direction: column;
    border-bottom: none;
    align-items: flex-start;
  }
}

.actionButton {
  width: 120px;
  height: 36px;
  border: 1px solid $tableBorder;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  @extend .cursorPointer;
}

.bubbleContent {
  font-size: 12px;
  line-height: 100%;
  color: white;
  font-weight: 500;
}

@include mobile {
  .bubbleContent {
    font-size: 12px;
  }
}

@include mobile {
  .actionButton {
    border: none;
  }
}

.speechBubbleWrapper {
  width: 80px;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -4px;
}

@include mobile {
  .speechBubbleWrapper {
    bottom: 100%;
    top: unset;
    margin-bottom: -4px;
  }
}

.statusWrapper {
  display: flex;
  align-items: center;
  & > *+* {
    margin-left: 2px;
  }
}

.status {
  font-size: 16px;
  color: $brand;
  display: block;
  line-height: 100%;
  font-weight: bold;
}

@include mobile {
  .status {
    font-size: 15px;
  }
}

.giftReceiverName {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  color: $gray800;
}

.informationContainer {
  margin-top: 20px;
  @extend .flexRow;
}

.imageWrapper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
}

@include mobile {
  .imageWrapper {
    width: 80px;
    height: 80px;
  }
}

.buttonWrapper {
  position: relative;
  display: block;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
}

@include mobile {
  .buttonWrapper {
    flex: 1;
    margin-bottom: 0;
    border-right: 1px solid $tableBorder;
    &:last-of-type {
      border-right: none;
    }
  }

  .actionButton {
    width: 100%;
  }
}

.infoSection {
  flex: 1;
}

.infoWrapper {
  margin-left: 40px;
  flex: 1;
  margin-right: 80px;
}

@include mobile {
  .infoWrapper {
    margin-right: 0;
    margin-left: 20px;
  }

  .infoSection {
    width: 100%;
  }
}

.brand {
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  color: $typo2;
  display: block;
}

.productName {
  display: block;
  margin-top: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  color: $typo4;
}

@include mobile {
  .brand {
    font-size: 12px;
  }

  .productName {
    font-size: 14px;
    margin-top: 2px;
  }
}

.buttonSection {
  display: flex;
  flex-direction: row;
  border: 1px solid $tableBorder;
  margin-top: 20px;
  width: 100%;
  //z-index: 9;
}

.optionName {
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: $typo1;
  vertical-align: middle;
}

.pipeIcon {
  vertical-align: middle;
  margin: 0 4px;
}

.count {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: $typo1;
  vertical-align: middle;
}

@include mobile {
  .optionName {
    font-size: 12px;
  }
}

.optionInfoWrapper {
  margin-top: 8px;
  line-height: 0;
}

.price {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: $typo1;
  display: block;
  margin-top: 12px;
}

@include mobile {
  .price {
    font-size: 12px;
  }
}

.won {
  font-weight: 500;
}


.purchaseConfirmModalContainer {
  width: 416px;
  padding: 32px 16px 24px 16px;
  text-align: center;
}

@include mobile {
  .purchaseConfirmModalContainer {
    width: 300px;
    padding: 32px 16px 24px 16px;
  }
}

.modalTitle {
  font-weight: bold;
  line-height: 140%;
  font-size: 20px;
  color: $typo4;
  display: block;
}

.modalPoint {
  color: $brand;
}

.modalDescription {
  font-size: 14px;
  line-height: 140%;
  color: $typo2;
  font-weight: 500;
  display: block;
  margin-top: 12px;
  margin-bottom: 24px;
}

@include mobile {
  .modalTitle {
    font-size: 17px;
  }

  .modalDescription {
    font-size: 13px;
  }
}

.modalButtonWrapper {
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.modalGrantPoint {
  font-weight: bold;
  font-size: 32px;
  line-height: 100%;
  color: $brand;
  display: block;
  margin-top: 12px;
}

@include mobile {
  .modalGrantPoint {
    font-size: 28px;
  }
}

.modalGrantDone {
  margin-top: 12px;
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

@include mobile {
  .modalGrantDone {
    font-size: 15px;
  }
}

.reviewRewardContainer {
  background: $brandLight;
  border: 1px solid $brand;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.reviewRewardText {
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: $typo3;
  text-align: left;
}

.writeReviewButtonWrapper {
  width: 66px;
  background-color: $brand;
}

.reviewRewardEmphasis {
  color: $brand;
}

.modalTotalPointContainer {
  background-color: $lightBackground;
  height: 40px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  padding: 0 20px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  color: $typo2;
  //margin-bottom: 40px;
  margin-top: 24px;
}

@include mobile {
  .modalTotalPointContainer {
    font-size: 15px;
  }
}

.modalTotalPoint {
  color: $brand;
  font-weight: bold;
}

.reviewLink {
  vertical-align: middle;
  text-decoration: underline;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  color: $typo3;
  margin-right: 2px;
  @extend .cursorPointer;
}


@include mobile {
  .reviewLink{
    font-size: 14px;
  }
}
