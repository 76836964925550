@import "styles/utils.module";
@import "styles/color.module";

.container {}

@include tablet {
  .container {
    padding: 0 20px;
  }
}

@include mobile {
  .container {
    padding: 0;
  }
}

.inquiryContainer {
  padding-right: 16px;
  height: 72px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .borderUnderLine;
}

.queryDetailContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 16px;
  @extend .flexCol;
  @extend .borderUnderLine;
}

.expandedCardContainer {
  background-color: $lightBackground;
}

.thinBorder {
  border-bottom-width: 1px;
}

.queryDetailRow {
  @extend .flexRow;
}

.answer {
  margin-top: 28px;
}

.status {
  width: 106px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: $typo1;
  vertical-align: middle;
}

.queryType {
  width: 106px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: $brand;
  vertical-align: middle;
}

.sampleQuery {
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  color: $typo3;
  line-height: 16px;
  vertical-align: middle;
  word-break: break-all;
}

.query {
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  color: $typo3;
  line-height: 16px;
  vertical-align: middle;
  word-break: keep-all;
  overflow: hidden;
  word-wrap: break-word;
  width: 100%;
  height: auto;
}

.maxOneLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nickname {
  width: 140px;
  text-align: center;
  font-weight: bold;
  color: $typo3;
  font-size: 14px;
}

.date {
  padding: 0 32px;
  color: $typo1;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}

.errorContainer {
  text-align: center;
  margin: 80px 0;
}

.errorText {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.listRoot {
  width: 100%;
  max-width: 927px;
}

.bottomSpace {
  height: 80px;
}

@include tablet {
  .errorText {
    font-size: 15px;
  }
  .errorContainer {
    margin: 60px 0;
  }
}

@include mobile {
  .bottomSpace {
    height: 24px;
  }
  .errorContainer {
    margin: 40px 0;
  }
}