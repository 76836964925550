@import "styles/color.module";
@import "styles/utils.module";


.result {
  padding: 14px 20px;
  background-color: $lightBackground;
  border: 1px solid $tableBorder;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 12px;
  @extend .justifyContentCenter;
  @extend .flexCol;
}

.result:last-of-type {
  margin-bottom: 0;
}

@include tablet {
  .result {
    padding: 14px 20px;
  }
}

.selectorContainer {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  margin-top: 12px;
}

@include tablet {
  .selectorContainer {
    margin-top: 8px;
  }
}

.optionText {
  color: $typo3;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  display: block;
}

.price {
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

.won {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

@include mobile {
  .price {
    font-size: 16px;
  }
  .won {
    font-size: 12px;
  }
}

.resultContainer {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  padding: 24px 0 40px 0;
  position: absolute;
  left:0;
  bottom: 0;
  z-index: 999999999;
  width: 100%;
}

.bodyContainer {
  padding: 0 24px;
  margin-top: 6px;
}

@include mobile {
  .resultContainer {
    padding-bottom: calc(env(safe-area-inset-bottom) + 6px);
  }
}

.buyButtonWrapper {
  margin-top: 6px;
}

@include mobile {
  .buyButtonWrapper {
    margin-top: 4px;
  }
}

.soldOutText {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo1;
}

.purchaseInfoContainer {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 36px;
  & > *+* {
    margin-left: 8px;
  }
}

.loadingWrapper {
  padding: 40px 0;
}
