.currency {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
}

.arrowDownIcon {
  margin-left: 24px;
}

.item {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
}

.container {
  width: 100%;
}
