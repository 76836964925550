@import "styles/color.module";
@import "styles/utils.module";


.container {

}

.tabSelectorContainer {
  overflow: hidden;
}

.rowContainer {
  @extend .flexRow;
}

.element {
  flex: 1;
  height: 48px;
  border: 1px solid $tableBorder;
  @extend .cursorPointer;
  border-right: none;
  border-bottom: none;
  position: relative;
}

.noLeftBorder {
  border-left: none;
}

.noRightBorder {
  border-right: none;
}

.lightBorder {
  border-color: $border !important;
}

.elementTopLeft {
  //border-top-left-radius: 5px;
}

.elementTopRight {
  //border-top-right-radius: 5px;
}

.elementBottomLeft {
  //border-bottom-left-radius: 5px;
}

.elementBottomRight {
  //border-bottom-right-radius: 5px;
}

.borderRight {
  border-right: 1px solid $tableBorder;
}

.borderBottom {
  border-bottom: 1px solid $tableBorder;
}

.content {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
}

.grayInactiveContent {
  color: $typo2;
}

.darkActiveContent {
  color: $typo4;
  //font-weight: bold;
}

.activeContent {
  background-color: $brand;
  color: white;
  z-index: 99;
}

.faqContainer {
  margin-top: 60px;
}

.title {
  color: $typo4;
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  margin-bottom: 20px;
}


.paginatorMargin {
  height: 60px;
}

.selectorWrapper {}

.listRoot {
  width: 100%;
  border-top: 1px solid $border;
}

@include tablet {
  .container {
    padding: 0 20px;
  }

  .faqContainer {
    padding: 0 20px;
  }

  .title {
    font-size: 18px;
  }
}

@include mobile {
  .container {
    padding: 0;
  }

  .selectorWrapper {
    padding: 0 20px;
    margin-top: 24px;
  }

  .paginatorMargin {
    height: 40px;
  }

  .faqContainer {
    margin-top: 40px;
    padding: 0;
  }

  .title {
    font-size: 17px;
    padding: 0 20px;
  }
}
