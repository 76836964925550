@import "styles/utils.module";
@import "styles/color.module";

.root {
  width: 100%;
  display: grid;
  gap: 8px;  // gap is usable in display: grid.
}

.column_1 {
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;  // gap is usable in display: grid.
}

.column_2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 8px;  // gap is usable in display: grid.
}

.column_3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;  // gap is usable in display: grid.
}

.imageRoot {

}

.imageWrapper {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  @extend .no-select;
  isolation: isolate;
}

.showMore {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $gray900;
  opacity: 0.8;
  border-radius: 5px;
}
