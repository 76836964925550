@import "styles/utils.module";


.input {
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  color: $typo4;
  width: 100%;
  border: 1px solid $gray300;
  height: 44px;
  border-radius: 5px;
  position: relative;
  padding: 0 20px;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
}

.input:has(textarea:focus), .input:focus {
  border-color: $inputBorder;
}

.highlightBorder {
  caret-color: $brand;
}

.highlightBorder:has(textarea:focus), .highlightBorder:focus {
  border-color: $brand;
}

.textareaWrapper {
  height: 240px;
  padding: 16px;
  @extend .flexCol;
}

.textarea {
  width: 100%;
  border: none;
  font-size: 16px;
  white-space: pre-wrap;
  line-height: 140%;
  color: $typo4;
  flex: 1;
  resize: none;
}

.placeholder {
  color: $typo1;
  -webkit-text-fill-color: $typo1;
}

.maxLengthContainer {
  width: 100%;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  color: $typo3;
  @extend .justifyContentSpaceBetween;
  @extend .flexRow;
}

.maxLength {
  color: $typo1;
  -webkit-text-fill-color: $typo1;
}

.redText {
  color: red !important;
  -webkit-text-fill-color: red;
}

@include tablet {
  .input {
    font-size: 15px;
  }
}

.input::placeholder {
  @extend .placeholder;
}

.textarea::placeholder {
  @extend .placeholder;
}

.input:read-only {
  color: $typo4 !important;
  -webkit-text-fill-color: $typo4;
  opacity: 1;
}

.input_disabled {
  background-color: $gray100;
}

.input_readonly {
  background-color: white;
}

.dropdownInput {
  @extend .cursorPointer;
}

.dropdownInputActive {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: $brand;
}

.imageInputContainer {
  border: 1px solid $brand;
  width: 100%;
  height: 100%;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .flexCol;
  text-align: center;
  border-radius: 5px;
  @extend .cursorPointer;
  position: relative;
}

.imageInputText {
  color: $brand;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  margin-top: 4px;
  text-align: center;
}

.imageInputInvisible {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @extend .cursorPointer;
  opacity: 0;
}

.imagePreviewWrapper{
  width: 100%;
  height: 100%;
  position: relative;
}

.imagePreview{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.closeButton {
  background-color: rgba($tableBorder, 0.7);
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  position: absolute;
  top: 8px;
  right: 8px;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  & > svg > path {
    fill: white;
  }
  @extend .cursorPointer;
}

.selectorContainer {
  width: 100%;
  position: absolute;
  max-height: 240px;
  overflow-y: auto;
  border: 1px solid $tableBorder;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  z-index: 99999;
  background-color: white;
}

.choice {
  padding: 12px 22px;
  color: $typo3;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  @extend .cursorPointer;
}

.label {
  margin-bottom: 12px;
  display: block;
  color: $typo3;
  line-height: 100%;
  font-size: 16px;
  font-weight: 500;
}

@include tablet {
  .choice {
    font-size: 15px;
  }
}


@include tablet {
  .textarea {
    font-size: 15px;
  }
}

@include mobile {
  .label {
    font-size: 15px;
  }

  .maxLengthContainer {
    font-size: 14px;
  }

  .textarea {
    font-size: 15px;
  }

  .imageInputText {
    font-size: 13px;
  }
}

.error {
  border-color: red !important;
}

.errorMessage {
  font-size: 13px;
  line-height: 100%;
  color: red;
  font-weight: 500;
  margin-top: 8px;
  white-space: pre;
  text-align: start;
}

.checkedInputErrorContainer {
  line-height: 0;
  margin-right: 8px;
  display: inline-block;
}

.checkedInputErrorText {
  line-height: 130%;
  font-size: 12px;
  color: red;
  font-weight: 500;
  vertical-align: middle;
}

.checkedInputErrorIcon {
  color: red;
  font-weight: 500;
  vertical-align: middle;
  margin-right: 2px;
}

.validText {
  color: blue;
}

.dark {
  background-color: $border;
}

.select {
  @extend .cursorPointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.right {
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  @extend .flexRow;
  @extend .alignItemsCenter;
}
