@import "styles/color.module";
@import "styles/utils.module";

.root {
  position: relative;
  padding: 32px 16px calc(env(safe-area-inset-bottom) + 50px) 16px;
}

.title {
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: $typo3;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 6px;
  }
}

.subtitle {
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  color: $gray800;
}

.description {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: $gray500;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > *+* {
    margin-left: 24px;
  }
}

.clipboard {
  background: none;
  border: none;
}

.shareButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.shareButtonText {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  color: $gray800;
}

.sellerShopHighlight {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: black;
  border-radius: 20px;
  margin-left: 4px;

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: white;
  }
}
