@import "styles/utils.module";

@each $name, $map in $themes {
  .theme-#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}

.buttonContainer {
  width: 100%;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-weight: bold;
  line-height: 100%;
  color: white;
  border: none;
  //border-radius: 5px;
  background-color: var(--brand);
  @extend .cursorPointer;
  font-family: SUIT-HMK;
}

.light {
  background-color: $tableBorder;
}

.lightInactive {
  background-color: white;
  border: 1px solid var(--gray300);
  color: $gray400;
}

.lightWhite {
  border: none;
  color: var(--gray500) !important;
  background-color: var(--gray200) !important;
}

.disabled {
  background-color: var(--disabled);
}

.white {
  background-color: var(--fullButtonBackground);
  border: 1px solid var(--fullButtonBackgroundBroder);
  color: var(--fullButtonBackgroundBroder);
}

.black {
  background-color: white;
  border: 1px solid var(--tableBorder);
  color: var(--typo3);
}

.totalBlack {
  background-color: var(--gray900);
  border: 1px solid var(--gray900);
  color: white;
}

.totalBlackInactive {
  background-color: white;
  border: 1px solid var(--gray200);
  color: white;
}

.red {
  background-color: var(--emphasis);
}
