@import "styles/color.module";
@import "styles/utils.module";


.container {
  margin: 140px auto 140px;
  width: 335px;
  max-width: $mobileMaxWidth;
  padding-bottom: calc(env(safe-area-inset-bottom));
}

.fullHeightContainer {
  height: 100%;
  //position: relative;
}

.newContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  max-width: $mobileMaxWidth;
  padding-bottom: calc(env(safe-area-inset-bottom));
}

.elementsTextInput {
 div > * {
   margin-top: 30px
 }
}

@include mobile {
  .container {
    width: 100%;
    padding: 0 20px;
    margin-top: 0;
  }

  .fullHeightContainer {
    margin-bottom: 0;
  }
}

.buttonContainer {
  margin-top: 24px;
  display: block;
}

.stickyButtonContainer {
  padding: 6px 0;
}

@include mobile {
  .stickyButtonContainer {
    position: fixed;
    bottom: 0;
    padding: 6px 20px;
    width: 100%;
    max-width: $mobileMaxWidth;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.content {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: $typo3;
  word-break: keep-all;
}

@include mobile {
  .content {
    font-size: 15px;
  }
}

.agreeModalContainer {
  padding: 24px 20px 6px 20px;
}

.welcomeButtonContainer {
  @extend .flexRow;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: $mobileMaxWidth;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 12px 16px calc(env(safe-area-inset-bottom) + 20px) 16px;
}

.completeButtonWrapper {
  width: 100%;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0;
  }
}

.welcome {
  display: block;
  margin: 10px 0 14px 0;
}

.welcomeText {
  font-size: 15px;
  line-height: 140%;
  font-weight: 600;
  color: $typo3;
  display: block;
  margin-bottom: 24px;
}

.welcomeList {
  line-height: 160%;
  font-size: 13px;
  color: $gray600;
  font-weight: 500;
  @extend .flexRow;
  margin-top: 4px;
  &:first-of-type {
    margin-top: 0;
  }
}

@include mobile {
  .welcome {
    margin: 8px 0 12px 0;
  }

  .welcomeList {
    font-size: 13px;
  }
}

.customerCenterContainer {
  margin-top: 60px;
}

.alertText {
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.customerCenterBox {
  background-color: $lightBackground;
  padding: 15px 20px;
  margin-top: 12px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.customerCenterTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: $typo3;
  display: block;
}

.customerCenterRuntime {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  line-height: 140%;
  font-weight: 500;
  color: $typo2;
}

.loginHelpContainer {
  color: $gray800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-top: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  padding-left: 20px;
}

.loginHelpContainerMail {
  color: $brand;
  background: none;
  border: none;
  display: flex;
  padding: 0;
}

.snsWrapper {
  margin-top: 12px;
}

.snsType {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  vertical-align: middle;
}

.findPasswordLinkText {
  color: $brand;
  text-decoration: underline;
  line-height: 100%;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  margin-right: 2px;
}

.findPasswordLinkWrapper {
  line-height: 0;
  margin-top: 24px;
  @extend .cursorPointer;
}

.findPasswordText {
  margin-top: 8px;
  font-size: 14px;
  line-height: 140%;
  color: $typo1;
  font-weight: 500;
  display: block;
}

.notFound {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: $typo1;
  line-height: 140%;
  margin-top: 12px;
}

.findContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

@include mobile {
  .findContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh !important;
    margin: 0 0 0 0;
  }

  .customerCenterContainer {
    margin-bottom: 80px;
  }

  .alertText {
    font-size: 14px;
  }

  .customerCenterTitle {
    font-size: 15px;
  }

  .customerCenterRuntime {
    font-size: 12px;
  }

  .findPasswordText {
    font-size: 13px;
  }

  .findPasswordLinkText {
    font-size: 14px;
  }

  .notFound {
    font-size: 13px;
  }
}

.bannerContainer {
  padding: 16px 24.5px 46px 24.5px;
}

.couponListContainer {
  display: flex;
  flex-direction: column;
}

.bulletContainer {
  margin-top: 12px;
}

.bannerWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 76.58%;
}

.wideSNSLoginButton {
  width: 100%;
  height: 48px;
  border-radius: 9999px;
  position: relative;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.wideSNSLoginButtonContainer {
  border-radius: 9999px;
  overflow: hidden;
}

.wideButtonText {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  padding: 0 18px;
}

.lastLoginBubble {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.lastLoginBubbleText {
  color: white;
  font-weight: 600;
  line-height: 13px;
  display: block;
  font-size: 13px;
}

.bottomButton {
  padding: 16px 0 calc(env(safe-area-inset-bottom) + 6px) 0;
}
