@import "styles/color.module";
@import "styles/utils.module";


.container {
  width: 100%;
  //border: 1px solid $tableBorder;
  //border-radius: 5px;
  overflow: hidden;
  text-align: left;
}

.noBorder {
  border: none;
}

.titleBar {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid $border;
}

.brand {
  font-size: 18px;
  line-height: 100%;
  color: $typo4;
  font-weight: bold;
}

.count {
  color: $typo4;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
}

@include tablet {

  .brand {
    font-size: 17px;
  }

  .count {
    font-size: 15px;
  }
}

@include mobile {

  .brand {
    font-size: 15px;
  }

  .count {
    font-size: 14px;
  }
}

.contentContainer {
  padding: 16px;
  @extend .flexRow;
  @extend .alignItemsStart;
}

.infoContainer {
  margin-left: 48px;
  flex: 1;
}

.title {
  color: $typo3;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  display: block;
  margin-bottom: 6px;
}

.optionName {
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  color: $typo2;
  line-height: 100%;
}

.pipe {
  margin: 0 8px;
  vertical-align: middle;
}

.delivery {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.optionContainer {
  line-height: 0;
  margin-bottom: 10px;
}

.price {
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
  vertical-align: middle;
}

.won {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
  margin-left: 2px;
}

@include tablet {
  .infoContainer {
    margin-left: 36px;
  }

  .title {
    font-size: 15px;
  }

  .optionName {
    font-size: 13px;
  }

  .pipe {
    margin: 0 4px;
  }

  .delivery {
    font-size: 13px;
  }

  .price {
    font-size: 18px;
  }

  .won {
    font-size: 14px;
  }
}

.sellerInfo {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $typo1;
  display: block;
  margin-bottom: 4px;

}

@include mobile {
  .infoContainer {
    margin-left: 12px;
    margin-top: 2px;
  }

  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
  }

  .optionName {
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
  }

  .pipe {
    margin: 0 2px;
    width: 12px;
    height: 12px;
  }

  .delivery {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: $typo2;
    margin-right: 8px;
  }

  .optionContainer {
    margin-bottom: 12px;
  }

  .price {
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;
    color: $typo4;
  }

  .won {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: $typo4;
  }
}

.smallPadding {
  padding: 16px;
}

.imageWrapper {
  //border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 76px;
  height: 76px;
}

.finalPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.couponExcluded {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #FF5B59;
}

.couponUnavailable {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $emphasis;
}
