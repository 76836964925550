@import "styles/color.module";
@import "styles/utils.module";


.topBarWrapper {
  width: 872px;
  margin-right: 0;
  margin-left: -20px;
}

.topBarMargin {
  border-bottom: 1px solid $border;
  flex: 1;
  background-color: white;
}

.topBarContainer {
  width: 100%;
  position: sticky;
  top: $topBarHeight;
  left: 0;
  z-index: 1999;
}

@include mobile {
  .topBarContainer {
    top: $topBarHeightMobile;
  }
}

@include tablet {
  .topBarWrapper {
    width: 100%;
    margin-left: 0;
  }
}

.firstReviewPointBubbleContainer {
  position: absolute;
  top: 45px;
  //background-color: #191919;
  //padding: 6px 12px;
  //
}

.firstReviewPointBubbleText {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #FFA0CC;
}

.productDetailBorder {
  height: 120px;
}

@include mobile {
  .productDetailBorder {
    height: 24px;
  }
}

.sectionBorder {
  height: 80px;
}

@include tablet {
  .sectionBorder {
    height: 60px;
  }
}

@include mobile {
  .sectionBorder {
    height: 24px;
  }
}

.infoSection {
  width: 100%;
  margin-bottom: 80px;
}

.infoContainer {
  flex: 2;
}

.infoSubTitle {
  color: $brand;
  margin-left: 2px;
}

.arrowRight {
  vertical-align: middle;
}

.topBarRoot {
  background-color: white;
  padding-top: 60px;
  //flex-wrap: nowrap;
}

@include tablet {
  .topBarRoot {
    margin-bottom: 116px;
  }
}

@include mobile {
  .topBarRoot {
    padding-top: 4px;
    margin-bottom: 24px;
  }
}

.productInfoSection {
  margin-bottom: 20px;
}

.imageInfoContainer {
  position: relative;
}

@include tablet {
  .imageInfoContainer {
    padding-left: $layoutLeftPaddingNew;
    padding-right: $layoutRightPaddingNew;
  }
}

.showMore {
  @extend .cursorPointer;
  width: 100%;
  height: 52px;
  border: 1px solid $brand;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  line-height: 100%;
  font-weight: bold;
  color: $brand;
  font-size: 16px;
  padding-left: 4px;
  margin-top: 16px;
}

.showMoreLarge {
  width: 302px;
  font-size: 18px;
}

.sideBar {
  flex: 1;
  min-width: 320px;
  background-color: white;
  margin-left: 40px;
}

.sideSelector {
  position: sticky;
  left: 0;
  top: $topBarHeight + 80;
  @extend .flexCol;
}


.imageWrapper {
  position: relative;
}

.errorContainer {
  text-align: center;
  margin-top: 40px;
}

.errorText {
  display: block;
  margin-top: 40px;
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
  color: $typo3;
}

.homeIcon {
  width: 24px;
  height: 24px;
  & path {
    fill: black;
  }
}

.videoWrapper {
  & video {
    display: block;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}

.vimeoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.vimeoAbsolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.htmlWrapper {
  & > img {
    display: block;
  }
}
