@import "../../../styles/color.module";
@import "../../../styles/utils.module";

.title {
  font-size: 17px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
  display: flex;
  margin-bottom: 16px;
}
