@import "styles/utils.module";
@import "styles/color.module";


.container {
  margin-top: 40px;
  border-top: 1px solid $border;

}

@include tablet {
  .container {
    margin-top: 20px;
  }
}

@include mobile {
  .container {
    margin-top: 0;
    border-top: 0;
    border-bottom: 10px solid $border;
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.header {
  height: 44px;
  background-color: $lightBackground;
  padding: 0 24px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}

@include mobile {
  .header {
    height: unset;
    border-bottom: 1px solid $border;
    background-color: white;
    padding: 24px 16px 16px 16px;
  }
}

.date {
  font-size: 16px;
  font-weight: bold;
  color: $typo4;
  line-height: 100%;
  vertical-align: middle;
}

.pipeIcon {
  vertical-align: middle;
  margin: 0 6px;
}

.orderNumber {
  vertical-align: middle;
  font-size: 15px;
  line-height: 100%;
  color: $typo2;
  font-weight: 500;
}

@include mobile {
  .date {
    font-size: 15px;
  }

  .pipeIcon {
    margin: 0 4px;
  }

  .orderNumber {
    font-size: 14px;
  }
}

.orderDetailLink {
  vertical-align: middle;
  font-size: 14px;
  color: $typo2;
  line-height: 100%;
  font-weight: 500;
}

.arrowIcon {
  vertical-align: middle;
  margin-left: 2px;
}
