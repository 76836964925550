@import "styles/color.module";
@import "styles/utils.module";


.modalContainer {
  padding: 22px 0 calc(env(safe-area-inset-bottom) + 36px) 0;
}

.productInfoWrapper {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-bottom: 18px;
  & > *+* {
    margin-left: 12px;
  }
  padding: 0 16px;
}

.imageWrapper {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
}

.pouchDoneText {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: $gray800;
  flex: 1;
}

.goToButtonWrapper {
  width: 81px;
}

.border {
  border: 1px solid $gray200;
}

.productListContainer {
  @extend .flexCol;
  margin-top: 20px;
}

.recommendedProductTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 100%;
  color: $gray800;
  padding: 0 16px;
  margin-bottom: 16px;
}

.arrowRightIcon {
  width: 20px;
  height: 20px;
}
