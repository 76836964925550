@import "styles/utils.module";
@import "styles/color.module";


.container {
  background-color: white;
  position: sticky;
  left: 0;
  bottom: env(safe-area-inset-bottom) !important;
  z-index: 10001;
  display: flex;
  flex-direction: column;
}

.buttonRowContainer {
  width: 100%;
  padding: 12px 16px 16px 16px;
}

.containerElevated {
  bottom: calc(env(safe-area-inset-bottom) + $mobilNavBarHeight) !important;
  transition: bottom 0.1s ease;
}

.likeIcon {
  width: 25px;
  height: 25px;

  & > path {
    opacity: 1;
  }
}

.unscrapLikeIcon {
}

.giftIcon {
  position: relative;
  cursor: pointer;
}

.speechBubbleWrapper {
  text-align: center;
  position: absolute;
  top: -100%;
  right: 50%;
  transform: translate(50%, 0);
  margin-top: -4px;
}

.bubbleContent {
  display: block;
  font-size: 13px;
  line-height: 100%;
  color: $brand;
  font-weight: 500;
  word-break: keep-all;
}

.selectorContainer {
  padding: 16px 0 calc(env(safe-area-inset-bottom) + 6px) 0;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.purchaseInfoContainer {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 36px;
  & > *+* {
    margin-left: 8px;
  }
}

.shortcutsContainer {
  display: flex;
  & > *+* {
    margin-left: 12px;
  }
  margin-right: 16px;
}

.openTimePrefix {
  color: $brand;
  margin-left: 3px;
}
