@import "styles/color.module";
@import "styles/utils.module";


.root {
  padding: 20px 0;
}

.sectionTitleWrapper {
  margin-bottom: 16px;
  @extend .flexRow;
  justify-content: space-between;
  align-items: center;
}

.sectionTitle {
  padding-left: $layoutLeftPaddingNew;
  padding-right: $layoutRightPaddingNew;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;

  color: $gray800;
}

.productsWrapper {
  @extend .flexRow;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  & > *+* {
    margin-left: 4px;
  }
}

.cardWrapper {
  flex: 0 0 135px;
}

.horizontalScrollPaddingLeft {
  flex: 0 0 $layoutLeftPaddingNew - 4px;
}

.horizontalScrollPaddingRight {
  flex: 0 0 $layoutRightPaddingNew - 4px;
}
