@import "styles/utils.module";


.couponContainer {
  padding: 16px 16px 16px 20px;
  border-bottom: 1px solid $border;
  @extend .flexRow;
  @extend .alignItemsCenter;
  justify-content: space-between;
}

.downloadCouponContainer {
  @extend .flexRow;
  padding: 0;
}

.infoContainer {
  padding: 16px 0 20px 20px;
  flex: 1;
  border: 1px solid $tableBorder;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.borderedContainer {
  border: 1px solid $tableBorder !important;
  border-radius: 5px;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.couponContainer:last-of-type {
  border-bottom: none;
}

.discount {
  color: $brand;
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  display: block;
}

.name {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: $typo3;
  display: block;
  margin-bottom: 8px;
}

.conditionText {
  font-weight: 500;
  color: $gray500;
  line-height: 100%;
  font-size: 12px;
  display: block;
  margin-bottom: 6px;
}

.period {
  color: $gray500;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  display: block;
}

.expiredPeriod {
  color: $typo1;
}

.expiredName {
  color: $tableBorder;
}

.radioButton {
  width: 20px;
  height: 20px;
}

.expiredDiscount {
  color: $disabled;
}

@include tablet {
  .discount {
    font-size: 18px;
  }

  .period {
    font-size: 13px;
  }
}

@include mobile {
  .discount {
    font-size: 24px;
  }

  .period {
    font-size: 12px;
  }
}

.totalDiscount {
  font-size: 13px;
}

.discountAmountWrapper {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-bottom: 8px;
}

.couponLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 3px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.doubleDiscountLabel {
  @extend .couponLabel;
  color: $brand;
  background-color: $hemekoN01;
}

.appOnlyLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: $brand;
  background-color: black;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 5px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.giftOnlyLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: $gray800;
  background-color: $gray200;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 5px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.duplicateAllowedLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: $gray500;
  background-color: $gray200;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 5px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.tagsContainer {
  margin-bottom: 13px;
}

.timerLabel {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: white;
  background-color: #FF5B59;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 5px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.fifoOver {
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  color: white;
  background-color: #FF5B59;
  padding: 4px 8px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 5px;
  margin-left: 5px;
  &:first-of-type {
    margin-left: 0;
  }
}

.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand;
  width: 54px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.downloadIcon {
  width: 24px;
  height: 24px;
}

.checkIcon {
  width: 24px;
  height: 24px;
}

.inactiveDownloadIcon {
  & > path {
    stroke: $gray500;
  }
}
