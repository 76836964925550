@import "styles/color.module";
@import "styles/utils.module";

.tileContentWrapper {
  position: relative;
  z-index: 3;
}

.tileContent {
  color: $gray800;
  font-size: 10px;
  position: absolute;
  left: 50%;
  z-index: 3;
}

.icon {
  height: 18px;
  width: 18px;
}
