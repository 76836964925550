@import "styles/color.module";
@import "styles/utils.module";


.container {
  display: block;
  background-color: white;
}

.tabItemContainer {
  height: $topTabBarHeightDesktop;
  flex: 1;
  font-size: 17px;
  line-height: 100%;
  font-weight: bold;
  @extend .cursorPointer;
}

@include tablet {
  .tabItemContainer {
    font-size: 16px;
  }
}

@include mobile {
  .tabItemContainer {
    font-size: 14px;
  }
}

.tabItemInactive {
  border-bottom: 1px solid $border;
  padding-bottom: 2px;
  color: $typo3;
}

.tabItemActive {
  border-bottom: 3px solid $brand;
  color: $brand;
}

.gray {
  color: $gray400;
  font-weight: 600;
}

@include mobile {
  .tabItemInactive {
    padding-bottom: 1px;
  }

  .tabItemActive {
    border-bottom: 2px solid $brand;
  }
}

.subTitle {
  color: $typo2;
  margin-left: 2px;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
}

.subTitleActive {
  color: $brand;
}

@include tablet {
  .subTitle {
    font-size: 14px;
  }
}

@include mobile {
  .subTitle {
    font-size: 13px;
  }
}
