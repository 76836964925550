@import "styles/color.module";
@import "styles/utils.module";

.topBar {
  height: $topBarHeightMobile;
  min-height: $topBarHeightMobile;
  position: sticky;
  width: 100%;
  max-width: $mobileMaxWidth;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.transparentBackground {
  background: none;
}

.container {
  height: 100%;
  margin: auto;
  padding: 0 16px;
  @extend .alignItemsCenter;
}

.searchBarContainer {
  width: 360px;
  margin-left: 146px;
}

@include tablet {
  .searchBarContainer {
    margin-left: 28px;
    width: 295px;
  }
}

@include mobile {
  .searchBarContainer {
    margin-left: 0;
    width: 100%;
  }
}

.loginContainer {
  display: inline-block;
  margin-left: 28px !important;
}

.element {
  margin-left: 16px;
  vertical-align: middle;
  display: inline-block;
  @extend .cursorPointer;
  position: relative;
}

.itemCount {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: $brand;
  width: 18px;
  height: 18px;
  border-radius: 9999px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  color: #FFFFFF;
}

.icon {}

@include tablet {
  .loginContainer {
    margin-left: 20px !important;
  }
}

@include mobile {
  .icon {
    width: 24px;
    height: 24px;
  }
}

.element:first-of-type {
  margin-left: 0;
}

.loginText {
  margin-left: 8px;
  font-weight: bold;
  line-height: 100%;
  font-size: 16px;
  color: $typo4;
}

.mobileSearch {
  width: 100%;
  height: 100%;
}

.notificationNewBadge {
  width: 6px;
  height: 6px;
  overflow: hidden;
  border-radius: 9999px;
  background-color: $brand;
  position: absolute;
  top: 2px;
  right: 3px;
}

.leftIcon {
  width: 24px;
  height: 24px;

  & path {
    fill: black;
  }
}

.whiteIcon {
  & path {
    fill: white;
  }
}

.whiteSearchIcon {
  & path {
    stroke: white;
  }
}

.whitePouchIcon {
  & path {
    fill: none;
    stroke : white;
  }
  & {
    stroke: black;

    fill: black;
  }
}

.logoWrapper {
  cursor: pointer;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.pro {
  color: white;
  background-color: $brand;
  height: 20px;
  font-size: 12px;
  line-height: 100%;
  font-weight: bold;
  @extend .flexRow;
  @extend .alignItemsCenter;
  width: 40px;
  @extend .justifyContentCenter;
  border-radius: 9999px;
  margin-left: 5px;
}

.betaCandidate {
  @extend .pro;
  width: 32px;
  font-size: 9px;
  background-color: $gray400;
}

.beta {
  @extend .pro;
  background-color: $blue;
}
