@import "styles/color.module";
@import "styles/utils.module";


.container {
  padding: 20px 24px;
  background-color: white;
  border-bottom: 1px solid $border;
}

.clickable {
  @extend .cursorPointer;
}

.containerActive {
  background-color: $lightBackground;
}

.icon {
  font-size: 16px;
  line-height: 140%;
  font-weight: bold;
  color: $brand;
  margin-right: 12px;
  vertical-align: middle;
}

.content {
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  color: $typo3;
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
  vertical-align: middle;
  //word-wrap: break-word;
  //overflow-wrap: anywhere;
}

.answerContainer {
  margin-top: 20px;
  @extend .flexRow;
}

.answerState {
  margin-bottom: 8px;
  line-height: 100%;
  font-size: 16px;
  color: $typo1;
  font-weight: bold;
  display: block;
}

.answerMeta {
  margin-bottom: 8px;
  display: block;
  line-height: 0;
  //@extend .flexRow;
}

.answerMetaIcon {
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
}

.answerMetaItem {
  vertical-align: middle;
  color: $typo1;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.answerDate {
  vertical-align: middle;
}

.answered {
  color: $brand;
}

.answeredStateContainer {
  @extend .flexRow;
}

.answeredStateWrapper {
  flex: 1;

}

.deleteButton {
  width: 35px;
}

.deleteButtonText {
  font-size: 16px;
  color: $typo1;
  font-weight: 500;
  line-height: 100%;
}

.image {
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.secretWrapper {
  line-height: 100%;
}

.secretText {
  vertical-align: middle;
}

.secretLock {
  margin-left: 8px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.productInfoWrapper {
  margin-bottom: 16px;
  color: $typo1;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  display: block;
  @extend .flexRow;
}

.productInfo {
  color: $brand;
  text-decoration: underline;
  vertical-align: middle;
}

@include tablet {
  .icon {
    font-size: 15px;
  }

  .content {
    font-size: 15px;
  }

  .answerMeta {
    margin-bottom: 8px;
  }

  .answerState {
    font-size: 15px;
  }

  .deleteButtonText {
    font-size: 14px;
  }

  .answerMetaItem {
    font-size: 13px;
  }
}

@include mobile {
  .container {
    padding: 20px 20px;
  }

  .answerMetaItem {
    font-size: 13px;
  }

  .image {
    width: 50px;
    height: 50px;
  }
}