@import "styles/color.module";
@import "styles/utils.module";

.border {
  background-color: $border;
  height: 1px;
}

.title {
  font-size: 18px;
  line-height: 100%;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  margin-top: 2px;
}

@include tablet {
  .title {
    font-size: 15px;
  }
}

.titleContainer {
  margin-top: 20px;
  line-height: 0;
  padding: 0 20px;
}

@include mobile {
  .titleContainer {
    padding: 0 6px;
  }
}

.container {
  padding-bottom: 24px;
  color: $typo3;
  transition: height 5s ease-out;
  @extend .cursorPointer;
}

@include mobile {
  .container {
    padding-bottom: 0;
  }
}

.body {
  //padding-right: 20px;
  //padding-left: 20px;
  margin-left: 8px;
  //padding-top: 16px;
  max-height: 300px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.bodyRow {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  vertical-align: middle;
}

.rowWrapper {
  display: flex;
}

@include tablet {
  .body {
    //padding-left: 6px;
    //padding-right: 6px;
  }

  .bodyRow {
    font-size: 15px;
  }
}

.fold {
  max-height: 0;
  overflow: hidden;
  transition: none;
  padding-top: 0;
}