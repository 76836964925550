@import "styles/color.module";
@import "styles/utils.module";

.selector {
  height: 56px;
  padding: 0 16px;
  border: 1px solid $tableBorder;
  width: 100%;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  color: $typo3;
  border-radius: 5px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .cursorPointer;
  @extend .alignItemsCenter;
}

.arrowUpIcon > * {
  fill: $brand;
}

.selectorSmall {
  height: 44px;
  font-size: 15px;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.optionList {
  width: 100%;
  flex: 1;
  position: absolute;
  left: 0;
  top: 100%;
  border: 1px solid $tableBorder;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: none;
  //border-top-color: $brand;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 280px;
}

@include mobile {
  .optionList {
    position: static;
    max-height: 306px;
  }
}

.selectActivated {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: $brand;
  border-color: $brand;
  z-index: 99;
}

.disabled {
  opacity: 0.5;
}

.optionElement {
  @extend .justifyContentSpaceBetween;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
  padding: 0 16px;
  height: 74px;
  background-color: white;
  z-index: 999;
  border-top: 1px solid $border;
}

.optionElement:first-of-type {
  border-top: 0;
}

.optionElementSmall {
  height: 57px;
}

.optionNameWrapper {
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.discountRate {
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: $brand;
  margin-right: 6px;
}

.optionName {
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  color: $typo3;
}

.optionNameSoldOut {
  color: $typo1;
}

.optionPriceWrapper {
  min-width: 80px;
  @extend .flexCol;
  justify-content: flex-end;
  align-items: flex-end;
}

.optionOpenTime {
  line-height: 120%;
  font-weight: 500;
  font-size: 14px;
  color: $brand;
}

.optionPrice {
  line-height: 100%;
  vertical-align: middle;
  font-weight: 500;
  font-size: 12px;
  color: $typo1;
  text-decoration: line-through;
  margin-bottom: 2px;
}

.optionDiscountedPrice {
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

.optionWon {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

.soldOutText {
  font-size: 14px;
  font-weight: 500;
  color: $typo1;
  line-height: 100%;
}
