@import "styles/color.module";
@import "styles/utils.module";


.container {
  @extend .flexRow;
}

@include tablet {
  .container {
    flex-direction: column;
  }
}

.infoSection {
  flex: 2;
}

.confirmSection {
  display: block;
  min-width: 320px;
  flex: 1;
  margin-left: 40px;
}

.infoContainer {
  border: 1px solid $tableBorder;
 // border-radius: 5px;
  overflow: hidden;
  height: auto;
}

@include tablet {
  .confirmSection {
    margin-left: 0;
  }

  .infoContainer {
    border: none;
    border-radius: 0;
  }
}

.title {
  font-size: 17px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
  display: flex;
  margin-bottom: 20px;
}

.subTitle {
  font-size: 18px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
  margin-bottom: 16px;
}

.modalContainer {
  @extend .newSidePadding;
  display: flex;
  flex-direction: column;
}

@include tablet {
  .subTitle {
    font-size: 17px;
  }
}

@include mobile {
  .subTitle {
    font-size: 15px;
  }
}

.modalContainer {
  @extend .newSidePadding;
  display: flex;
  flex-direction: column;
}

.couponsContainer {
  @extend .flexCol;
  flex: 0 1 auto;
  border: none;
  max-height: none;
  @extend .cursorPointer;
  margin-top: 20px;
}

.lastCouponsContainer {
  @extend .flexCol;
  flex: 0 1 auto;
  border: none;
  max-height: none;
  @extend .cursorPointer;
  margin-top: 20px;
  padding-bottom: 120px;
}

.couponModalSubtitle {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  color: $gray800;
}

.downloadableCouponContainer {
  margin-bottom: 12px;
}

.downloadableCouponContainer {
  margin-bottom: 12px;
}

.couponModalButtonContainer {
  background-color: white;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: env(safe-area-inset-bottom);
  padding: 6px 0 6px;
  z-index: 10001;
  display: flex;
  flex-direction: column;
}


.parenthesis {
  color: $brand;
}

.section {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.textContent {
  font-size: 15px;
  line-height: 100%;
  color: $gray800;
  font-weight: 500;
}

.optionCard {
  margin-top: 0;
  border-top: 1px solid $border;
}

.optionCard:first-of-type {
  margin-top: 16px !important;
  border-top: none;
}

.priceContainer {
  background-color: $lightBackground;
  padding: 40px 32px;
  border-bottom: 1px solid $tableBorder;
}

@include tablet {
  .priceContainer {
    border-bottom: none;
    padding: 20px;
    //border-radius: 5px;
    overflow: hidden;
  }
}

@include mobile {
  .priceContainer {
    padding: 20px 24px;
  }
}

.agreementContainer {
  padding: 28px 16px;
  background-color: white;
}

@include tablet {
  .agreementContainer {
    margin-top: -20px;
  }
}

@include mobile {
  .agreementContainer {
    padding: 24px 16px;
    margin-top: 0;
  }
}

.priceTitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
  display: block;
  margin-bottom: 24px;
}

.priceRow {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.priceKey {
  color: $typo2;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
}

.priceValue {
  color: $typo4;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  vertical-align: middle;
}

.priceWon {
  font-size: 14px;
  vertical-align: middle;
}

.border {
  border-top: 1px solid $tableBorder;
  margin: 0px -8px;
}

@include tablet {
  .border {
    margin: 0 0;
  }
}

@include mobile {
  .border {
    margin: 0 -4px;
  }
}

.totalPriceMargin {
  height: 20px;
}

.totalPriceRow {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  margin-top: 20px;
  margin-bottom: 8px;
}

.totalPriceKey {
  color: $typo4;
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
}

.totalPriceValue {
  color: $emphasis;
  font-size: 22px;
  font-weight: bold;
  line-height: 100%;
  vertical-align: middle;
}

.totalPriceWon {
  font-size: 18px;
  color: $typo4;
  font-weight: 500;
  vertical-align: middle;
}

.mileageText {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.mileage {
  font-weight: bold;
}

@include tablet {
  .priceKey {
    font-size: 16px;
  }

  .priceValue {
    font-size: 16px;
  }

  .priceWon {
    font-size: 12px;
  }

  .totalPriceMargin {
    height: 2px;
  }

  .totalPriceRow {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .totalPriceKey {
    font-size: 18px;
  }

  .totalPriceValue {
    font-size: 22px;
  }

  .totalPriceWon {
    font-size: 18px;
  }

  .mileageText {
    font-size: 13px;
  }
}


@include mobile {
  .priceRow {
    margin-bottom: 16px;
  }

  .priceKey {
    font-size: 15px;
  }

  .priceValue {
    font-size: 15px;
  }

  .priceWon {
    font-size: 12px;
  }

  .totalPriceMargin {
    height: 16px;
  }

  .totalPriceRow {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .totalPriceKey {
    font-size: 17px;
  }

  .mileageText {
    font-size: 13px;
  }
}

.checkbox {
  vertical-align: middle;
}

.agree {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $typo3;
}

@include tablet {
  .agree { font-size: 16px; }
}

@include mobile {
  .agree { font-size: 15px; }
}

.agreeTitle {
  line-height: 120%;
  font-weight: 500;
  color: $typo2;
  font-size: 14px;
}

.hemeticketWarningContainer {
  display: flex;
  & > *+* {
    margin-top :4px;
  }
  flex-direction: column;
}

.hemeticketWarningTitle {
  line-height: 100%;
  font-weight: 700;
  color: $emphasis2;
  font-size: 16px;
  padding-bottom: 2px;
}

.hemeticketWarning {
  line-height: 120%;
  font-weight: 500;
  color: $emphasis2;
  font-size: 14px;
}

.agreeSubtitle {
  font-size: 13px;
}

.agreeSeeMore {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  color: $typo1;
  line-height: 100%;
}

.agreementRow {
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @extend .cursorPointer;
}

.agreementSubRow {
  margin-bottom: 8px;
}

.totalAgree {
  margin-top: 24px;
  display: inline-block;
  color: $typo3;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

@include mobile {
  .agreeTitle {
    font-size: 13px;
  }
  .agreementRow {
    margin-bottom: 16px;
  }
  .agreeSeeMore {
    font-size: 13px;
  }
}


.inputContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-bottom: 16px;
}

.cityInputContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-bottom: 16px;
}

@include mobile {
  .inputContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}

.inputContainer:last-of-type {
  margin-bottom: 0;
}

.inputWrapper {
  flex: 2;
  @extend .flexRow;
  @extend .alignItemsCenter;
  & > *+* {
    margin-left: 6px;
  }
}

@include mobile {
  .inputWrapper {
    width: 100%;
  }
}

.inputColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.secondInputWrapper {
  flex: 1;
  margin-left: 12px;
}

.inputLabel {
  color: $typo3;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  width: 130px;
  margin-bottom: 8px;
  display: block;
}

.topMargin {
  height: 60 - $topPadding;
}

@include tablet {
  .topMargin {
    height: 0;
  }
}

@include mobile {
  .topMargin {
    height: 16px;
  }
}

.sideBarContainer {
  position: sticky;
  top: 100px;
  left: 0;
}

.emptyLabel {}

.narrowContainer {}

@include tablet {
  .emptyLabel {
    margin-bottom: 0;
  }

  .narrowContainer {
    margin-bottom: 8px !important;
  }
}

.agreementBorder {
  margin-top: 20px;
}

@include tablet {
  .agreementBorder {
    margin-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid $border;
  }
}

.payButtonContainer {
  background-color: white;
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 6px 20px calc(env(safe-area-inset-bottom) + 4px) 20px;
}

.paypalLoading {
  background-color: red;
}

.brief {
  font-size: 14px;
  line-height: 120%;
  color: $gray800;
  font-weight: 500;
  @extend .flexRow;
}

.optionCount {
  color: $emphasis;
}

.rightActionArrow {
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-left: 4px;
}

.rightActionArrowDisabled {
  vertical-align: middle;
  height: 16px;
  width: 16px;
  & path {
    fill: $gray450;
  }
}


@include mobile {
  .rightActionArrow {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }
}

.rightAction {
  vertical-align: middle;
  text-decoration: underline;
  color: $brand;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
}

.rightActionDisabled {
  vertical-align: middle;
  text-decoration: underline;
  color: $gray450;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
}


@include tablet {
  .rightAction {
    font-size: 14px;
  }
}

.selfVerificationHelperText {
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: $typo3;
  margin-bottom: 20px;
}

.selfVerificationButtonWrapper {
  margin-top: 20px;
}

.ordererInfo {
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: $typo3;
  display: block;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

@include tablet {
  .selfVerificationHelperText {
    font-size: 15px;
  }

  .ordererInfo {
    margin-bottom: 4px;
    font-size: 15px;
  }
}

@include mobile {
  .selfVerificationButtonWrapper {
    width: 100%;
  }

  .selfVerificationHelperText {
    line-height: 140%;
  }
}

.appModalContainer {
  background-color: white;
  padding: 32px 27px calc(env(safe-area-inset-bottom) + 24px);
  position: relative;
  @extend .flexCol;
}

.appModalContentWrapper {
  padding: 0 17px 0 5px;
  margin-bottom: 24px;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.appModalContent {
  margin-left: 16px;
  line-height: 140%;
  font-weight: 700;
  font-size: 20px;
  color: $gray800;
  flex: 1;
}

.appModalCloseButton {
  position: absolute;
  top: 16px;
  right: 16px;
  @extend .cursorPointer;
  & path {
    fill: black;
  }
}

.authCodeWrapper {
  margin-top: 12px;
}

.changeNumber {
  font-size: 12px;
  line-height: 100%;
  color: $typo1;
  font-weight: 500;
  margin-top: 12px;
  text-decoration: underline;
  @extend .cursorPointer;
}

.resendButton {
  @extend .cursorPointer;
  height: 24px;
  background-color: $brand;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentSpaceBetween;
  color: white;
  font-size: 12px;
  //border-radius: 3px;
  padding: 0 10px;
  font-weight: 600;
}

.existModalContainer {
  background-color: white;
  padding: 32px 16px 16px;
  position: relative;
  @extend .flexCol;
}

.existModalText {
  text-align: center;
  font-size: 17px;
  line-height: 140%;
  font-weight: bold;
  color: $typo3;
}

.existModalAccountInfoContainer {
  //border-radius: 5px;
  background-color: $gray100;
  height: 54px;
  margin-top: 20px;
  margin-bottom: 24px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

.existModalEmail {
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: $typo3;
}

.sameAsOrderer {
  line-height: 100%;
  font-size: 14px;
  font-weight: 500;
  color: $typo3;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
}

.memoContainer {
  margin-top: 8px;
}

.checkoutButtonContainer {
  padding: 6px 16px 6px 16px;
  position: sticky;
  background-color: white;
  left: 0;
  bottom: calc(env(safe-area-inset-bottom) + $mobilNavBarHeight);
  z-index: 10001;
}
