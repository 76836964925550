@import "styles/utils.module";
@import "styles/color.module";

.modalSection {
  margin-bottom: 40px;
}

.modalContainer {
  padding: 40px;
}

.modalSectionTitle {
  font-size: 16px;
  font-weight: bold;
  color: $typo4;
  line-height: 100%;
  margin-bottom: 20px;
  display: block;
}

.bulletText {
  margin-bottom: 4px;
}

.bulletTextHighlight {
  font-weight: bold;
}

.modalSectionSubtitle {
  font-size: 14px;
  font-weight: 500;
  color: $typo2;
  line-height: 100%;
  margin-left: 4px;
  vertical-align: middle;
  display: inline-block;
}

.modalImageSelector {
  width: 116px;
  height: 116px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}

.modalImagePreview {
  width: 116px;
  height: 116px;
  display: inline-block;
  vertical-align: middle;
}

.modalImageSelectorBullet {
  margin-top: 12px;
}

.modalCheckboxTextWrapper {
  margin-top: 12px;
}


.modalSubmitButtonContainer {
  margin-top: 32px;
  @extend .flexRow;
}

.modalSubmitButtonWrapper {
  @extend .fullFlex;
  margin-right: 9px;
  &:last-of-type {
    margin-right: 0;
  }
}

@include tablet {
}

@include mobile {
  .modalContainer {
    padding: 20px;
  }

  .modalSubmitButtonContainer {
    margin-top: 24px;
  }

  .modalImageSelector {
    width: 80px;
    height: 80px;
  }

  .modalImagePreview {
    width: 80px;
    height: 80px;
  }
}