@import "styles/color.module";
@import "styles/utils.module";


.container {
  @extend .flexRow;
  //display: block;
  background-color: white;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.leftPadding {
  min-width: 12px;
  border-bottom: 1px solid $border;
}

.rightPadding {
  flex: 1;
  border-bottom: 1px solid $border;
}

.itemLeftPadding {
  border-bottom: 1px solid $border;
  width: 6px;
}

.additionalRightPadding {
  min-width: 12px;
  border-bottom: 1px solid $border;
}

.itemRightPadding {
  border-bottom: 1px solid $border;
  width: 6px;
}

.tabItemContainer {
  flex: 0 0 auto;
  height: $newTopTabBarHeightDesktop;
  font-size: 17px;
  line-height: 100%;
  font-weight: bold;
  z-index: 1;
  @extend .cursorPointer;
  @extend .flexRow;
}

@include tablet {
  .tabItemContainer {
    height: $newTopTabBarHeightTablet;
    font-size: 16px;
  }
}

@include mobile {
  .tabItemContainer {
    height: $newTopTabBarHeightMobile;
    font-size: 14px;
  }
}

.title {
  padding: 16px 2px;
  color: $typo3;
}

@include mobile {
  .title {
    padding: 13px 2px;
  }
}

.tabItemInactive {
  border-bottom: 1px solid $border;
}

.tabItemActive {
  border-bottom: 2px solid $brand;
  color: $brand;
}

.subTitle {
  color: $brand;
  margin-left: 2px;
  padding: 16px 0;
}
