@import "styles/utils.module";

.container {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  line-height: 100%;
  color: $typo1;
  @extend .flexRow;
  text-align: center;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
}

.index {
  width: 28px;
  height: 28px;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .cursorPointer;
}

.active {
  font-weight: bold;
  color: $brand;
}