@import "styles/utils.module";
@import "styles/color.module";

.bulletText {
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  color: $typo2;
  padding-right: 8px;
}

.text {
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  color: $typo2;
  word-break: keep-all;
  word-wrap: break-word;
}

