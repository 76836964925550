@import "styles/color.module";
@import "styles/utils.module";


.titleContainer {
  @extend .justifyContentSpaceBetween;
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 0;
  height: 50px;
  min-height: 50px;
}

.darkContainer {
  background-color: $typo4;
}

.modalTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  color: $typo4;
}

.darkTitle {
  color: white;
}

.element {
  margin-left: 16px;
  vertical-align: middle;
  display: inline-block;
  @extend .cursorPointer;
  position: relative;
}

.icon {
  width: 24px;
  height: 24px;
}
