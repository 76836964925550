

.wrapper {
  width: 100%;
  height: 0;
  //position: relative;
  padding-bottom: 56.25%;
  //overscroll-behavior-x: none;
}

.player {
  width: 100%;
  height: 100%;
  position: absolute;

  & > div {
    width: 100%;
    height: 100%;
  }
}