@import "styles/color.module";
@import "styles/utils.module";


.upvoteWrapper {
  display: flex;
  border: 1px solid #DBDBDB;
  border-radius: 16px;
  align-items: center;
  width: fit-content;
  padding: 6px 10px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: $gray400;
}

.hasUpvotedWrapper {
  border-color: $brand;
  color: $brand;
}

.upvoteIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.notUpvotedIcon {
  & > * {
    fill: $gray400;
  }
}

.upvoteText {
  margin-right: 4px;
}
