@import "styles/utils.module";
@import "styles/color.module";


.container {
  width: 644px;
  padding: 40px;
  max-height: 720px;
  overflow-y: auto;
}

@include mobile {
  .container {
    width: 100%;
    max-height: none;
    height: 100%;
    padding: 0 16px 6px 16px;
  }
}

.adContainer {
  padding: 16px 20px;
  margin-top: 4px;
  background-color: black;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  @extend .flexRow;
  border-radius: 5px;
}

.adText {
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
}

.reviewPointContainer {

}

.reviewPointText {
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: white;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.reviewPoint {
  font-weight: bold;
  color: $brand;
  margin-left: 4px;
}

.optionSection {
  padding: 24px 0;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-bottom: 1px solid $border;
}

.imageWrapper {
  width: 80px;
  height: 80px;
  position: relative;
  margin-right: 40px;
  border-radius: 5px;
  overflow: hidden;
}

@include mobile {
  .imageWrapper {
    width: 62px;
    height: 62px;
    margin-right: 28px;
  }
}

.brand {
  display: block;
  margin-bottom: 12px;
  font-weight: bold;
  line-height: 100%;
  color: $typo3;
  font-size: 16px;
}

@include mobile {
  .brand {
    font-size: 14px;
  }
}

.productName {
  margin-bottom: 8px;
  color: $typo3;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  display: block;
}

@include mobile {
  .productName {
    font-size: 14px;
  }
}

.optionNameWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pipe {
  vertical-align: middle;
  margin: 0 4px;
}

.optionText {
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

@include mobile {
  .pipe {
    width: 12px;
    height: 12px;
  }

  .optionText {
    font-size: 12px;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: middle;
}

@include mobile {
  .title {
    font-size: 15px;
  }
}

.subTitle {
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: $typo2;
  vertical-align: middle;
  margin-left: 4px;
}

.subTitleRed {
  color: #FF5B59;
}

.scoreSection {
  margin-top: 24px;
}

.scoreText {
  font-size: 16px;
  display: block;
  line-height: 120%;
  font-weight: 500;
  color: $typo3;
  margin-bottom: 12px;
}

@include mobile {
  .scoreText {
    font-size: 15px;
  }
}

.selectorStarIcon {
  @extend .cursorPointer;
}

.expression {
  display: block;
  margin-top: 8px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  color: $brand;
}

.scoreError {
  color: red;
}

.contentSection {
  margin-bottom: 40px;
}

.photoSection {
  @extend .flexCol;
  margin-top: 40px;
}

.photoSectionDescriptionText {
  margin-top: 12px;
}

@include mobile {
  .photoSection {
    margin-bottom: 24px;
    @extend .flexCol;
  }
}

.imageRow {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -6px;
  flex-flow: row wrap;
  line-height: 0;
}

.imageCol {
  width: 20%;
  display: inline-block;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 12px;
}

@include mobile {
  .imageRow {
    margin-left: -20px;
    margin-right: -5px;
  }

  .imageCol {
    width: 25%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
}

.colWrapper {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
}

.colContent {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.confirmModalContainer {
  padding: 40px 24px;
  text-align: center;
}

.titleContainer {
  width: 100%;
}

.confirmModalText {
  font-size: 16px;
  display: block;
  font-weight: 500;
  line-height: 140%;
  color: $typo4;
  margin-bottom: 32px;
}

@include mobile {
  .confirmModalContainer {
    padding: 24px 20px;
  }

  .confirmModalText {
    font-size: 15px;
    margin-bottom: 24px;
  }
}

.successModalRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 20px 24px 20px;
}

.modalGrantPoint {
  font-weight: bold;
  font-size: 28px;
  line-height: 100%;
  color: $brand;
  display: block;
  margin-top: 8px;
}

.reviewDoneText {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
  margin-top: 16px;
}

.successModalButtonWrapper {
  width: 100%;
  margin-top: 32px;
}
