.svg {
  width: 280px;
  height: 280px;
  margin-top: -120px;
  display: block;
  margin-bottom: -110px;
}

.svgDot {
  width: 54px;
  height: 54px;
  margin-top: -27px;
  display: block;
  margin-bottom: -22px;
}

.whiteDots {
  & > svg > g > g > g > path {
    fill: #fff;
  }
}
