@import "styles/color.module";
@import "styles/utils.module";


.container {}

@include tablet {
  .container {
    padding: 0 20px;
  }
}

@include mobile {
  .container {
    padding: 0;
  }
}

.filterBar {
  padding: 12px 16px 4px 16px;
  margin-top: 12px;
  border-top: 1px solid $gray200;
}

.reviewCheckbox {
  vertical-align: middle;
  display: inline-block;
  @extend .cursorPointer;
}

.sortOrderContainer {
  @extend .flexRow;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $typo1;
}

.sortOrderSelected {
  color: $brand;
  font-weight: 700;
}

.photoReview {
  font-size: 15px;
  line-height: 100%;
  display: inline-block;
  color: $typo3;
  font-weight: 500;
  margin-left: 4px;
  vertical-align: middle;
}

.activeTab {
  font-weight: 500;
  color: $typo3;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  vertical-align: middle;
  @extend .cursorPointer;
}

.inactiveTab {
  font-weight: 500;
  color: $typo3;
  font-size: 15px;
  line-height: 100%;
  display: inline-block;
  vertical-align: middle;
  @extend .cursorPointer;
}

.cardContainer {
  padding: 20px 16px;
  @extend .borderUnderLine;
  line-height: 0;
  @extend .cursorPointer;
}

.emptyBottomBorder {
  border-bottom: none;
}

.ratingText {
  margin-left: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  color: $brand;
}

.heading {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  margin-bottom: 12px;
  align-items: center;
}

.myReviewHeading {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}

.product {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

@include tablet {
  .product {
    font-size: 15px;
  }
}

.productName {
  text-decoration: underline;
  color: $brand;
}

.productNameContent {
  display: inline !important;
}

.editButton {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo1;
  margin-left: 4px;
}

@include tablet {
  .editButton {
    font-size: 14px;
  }
}

.nicknameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nickname {
  line-height: 100%;
  font-size: 16px;
  font-weight: bold;
  color: $typo3;
  vertical-align: middle;
}

@include tablet {
  .nickname {
    font-size: 15px;
  }
}

.sponsoredIcon {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $gray800;
  background-color: $gray200;
  border-radius: 2px;
  height: 22px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 8px;
}

.imageContainer {
  display:flex;
  width:100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-bottom: 12px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.imageContainer::-webkit-scrollbar {
  display: none;
}

.horizontalScrollElement {
  flex: 0 0 80px;
  display: inline-block;
  margin-right: 4px;
  &:last-of-type {
    margin-right: 0;
  }
}

.imageWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

@include mobile {
  .horizontalScrollElement {
    flex: 0 0 76px;
  }
}

.content {
  //margin-top: 12px;
}

.date {
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  color: $gray500;
  display: block;
  margin-bottom: 0;
}

.text {
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  color: $typo3;
  display: inline-block;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
}

@include tablet {
  .text {
    font-size: 15px;
  }
}

.option {
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  color: $gray600;
  display: block;
  margin-bottom: 12px;
}

@include tablet {
  .option {
    font-size: 14px;
  }
}

@include mobile {
  .option {
    font-size: 13px;
  }
}

.focusedBackground {
  background-color: $lightBackground;
}

.emptyText {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: $typo2;
  margin: 80px 0;
}

@include tablet {
  .emptyText {
    margin: 60px 0;
    font-size: 15px;
  }
}

@include mobile {
  .emptyText {
    margin: 40px 0;
  }
}

.lineBreak {
  display: block;
  height: 6px;
}

.reviewReward {
  font-weight: bold;
  color: $brand;
}

.lightBackground {
  background-color: $lightBackground;
}

.detailDate {
  display: block;
  color: $typo1;
  line-height: 100%;
  font-size: 12px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 12px;
}

.seeMoreOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-color: rgba($typo4, 0.4);
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: white;
  & > svg > path {
    fill: white;
  }
  @extend .cursorPointer;
}

.photoListContainer {
  width:100%;
  margin-top: 20px;
}

.photoListElement {
  width: 14.285%;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  &:last-of-type {
    padding-right: 0;
  }
  &:first-of-type {
    padding-left: 0;
  }
}

.photoWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.statisticsContainer {
  width: 100%;
  border-radius: 5px;
  @extend .flexRow;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.statisticsWrapper {
  width: 100%;
  background: $lightBackground;
  border-radius: 5px;
  @extend .flexRow;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
}

.starRatingWrapper {}

@include tablet {
  .starRatingWrapper {
    padding: 0 20px;
  }

  .photoListContainer {
    display:flex;
    width:100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 20px;
  }

  .photoListContainer::-webkit-scrollbar {
    display: none;
  }

  .photoListElement {
    flex: 0 0 116px;
    display: inline-block;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 20px;
    }
    padding-left: 0;
    padding-right: 0;
  }
}

.paginator {
  margin-top: 60px;
}

@include tablet {
  .paginator {
    margin-top: 40px;
  }
}

.imageModal {
  position: relative;
  max-height: calc(100vh - 100px);
}

.upvoteWrapper {
  display: flex;
  border: 1px solid #DBDBDB;
  border-radius: 16px;
  align-items: center;
  width: fit-content;
  padding: 6px 10px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: $gray400;
}

.hasUpvotedWrapper {
  border-color: $brand;
  color: $brand;
}

.upvoteIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.notUpvotedIcon {
  & > * {
    fill: $gray400;
  }
}

.upvoteText {
  margin-right: 4px;
}

.hasUpvotedIcon > * {
  stroke: $brand;
}

.reviewTypeSelectorContainer {
  @extend .flexRow;
  @extend .sidePadding;
  & > *+* {
    margin-left: 7px;
  }
  margin-bottom: 12px;
}

.reviewTypeSelector {
  flex: 1;
  height: 36px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  border-radius: 5px;
  font-weight: 500;
  color: $typo1;
  background-color: white;
  border: 1px solid $gray300;
  font-size: 16px;
  @extend .cursorPointer;
}

.reviewTypeSelectorActive {
  background-color: $brand;
  border-color: $brand;
  font-weight: bold;
  color: white;
}

.brandInfoSection {
  @extend .sidePadding;
  padding-top: 12px;
  padding-bottom: 24px;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.brandImageWrapper {
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  background-color: $gray300;
  position: relative;
  overflow: hidden;
}

.brandName {
  margin-left: 8px;
  font-weight: bold;
  font-size: 15px;
  color: $gray800;
}

.mallReviewCount {
  font-size: 12px;
  font-weight: 500;
  color: $gray400;
  margin-left: 8px;
}

.reviewBorder {
  border-top: 1px solid $gray200;
}

.mallReviewContainer {
  padding: 20px 20px;
  border-bottom: 1px solid $gray200;
  &:last-of-type {
    border-bottom: none;
  }
}

.reviewMetaContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentSpaceBetween;
  height: 16px;
}

.mallReviewWriter {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $gray600;
}

.mallReviewDate {
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  color: $gray500;
}

.mallReviewContent {
  margin-top: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 160%;
  color: $gray800;
  display: block;
  white-space: pre-wrap;
}

.mallReviewImageContainer {
  display:flex;
  width:100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-top: 12px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mallReviewImageContainer::-webkit-scrollbar {
  display: none;
}

.mallReviewHorizontalScrollElement {
  flex: 0 0 70px;
  display: inline-block;
  margin-right: 4px;
  &:last-of-type {
    margin-right: 0;
  }
}

.mallImageWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  @extend .cursorPointer;
}

.noReviewContainer {
  padding: 56px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noReviewIcon {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noReviewFiltered {
  color: $gray400;
}

.noReviewText {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: $gray800;
  text-align: center;
}

.noReviewTextHighlight {
  color: $brand;
  font-weight: 700;
}

.writeFirstReviewButton {
  margin-top: 20px;
}

.writeFirstReviewButtonText {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: white;
    padding: 12px 29px;
}

.userInfoContainer {
  flex: 1;
  padding: 0 8px;
}

.userNickname {
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
  margin-right: 4px;
}

.profileImage {
  border-radius: 9999px;
  overflow: hidden;
  @extend .alignItemsCenter;
  width: 32px;
  height: 32px;
  @extend .cursorPointer;
}

.creatorBadge {
  width: 12px;
  height: 12px;
}

.filterWrapper {
  margin-top: 12px;
}

.filterWrapperEmpty {
    margin-top: -6px;
}

.reviewFilterContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 12px 20px 0 20px;
}

.reviewFilterButton {
  @extend .flexRow;
  padding: 0 16px;
  border-radius: 9999px;
  height: 33px;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: $gray800;
  background-color: $gray100;
  @extend .cursorPointer;
  margin-left: 8px;
  &:first-of-type {
    margin-left: 0;
  }
}

.reviewFilterButtonActive {
  color: white;
  background-color: $gray900;
}

.filterModalTitle {
  padding: 20px 16px 16px 16px;
  color: $gray800;
  font-size: 14px;
  line-height: 100%;
  font-weight: 700;
  border-bottom: 1px solid $gray200;
}

.filterModalSubtitle {
  padding: 16px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentSpaceBetween;
  color: $gray800;
  font-size: 13px;
  font-weight: 700;
  line-height: 100%;
}

.filterModalSelectAll {
  color: $gray400;
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  @extend .cursorPointer;
}

.reviewFilterContentContainer {
  height: 318px;
  position: relative;
  @extend .flexCol;
}

.reviewFilterSubmitContainer {
  padding-bottom: calc(env(safe-area-inset-bottom));
}

.reviewFilterSubmit {
  padding: 5px 16px 4px 24px;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.reviewFilterRefreshControl {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.reviewFilterRefreshText {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 20px;
  color: $gray800;
}

.reviewFilterSubmitButtonWrapper {
  flex: 1;
}

.filterModalRow {
  height: auto;
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 0 16px;
  margin-bottom: 16px;
  &:first-of-type {
    margin-top: 4px;
  }
  @extend .cursorPointer;
}

.modalFilterCheckBox {
  margin-right: 12px;
}

.filterModalColor {
  border-radius: 5px;
  width: 36px;
  height: 36px;
  margin-right: 16px;
}

.filterModalOptionName {
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  color: $gray800;
}

.filterModalOptionCount {
  color: $gray400;
}

.reviewFilterOptionArrow {
  width: 14px;
  height: 14px;
  & path {
    fill: $gray400;
  }
  transform: rotate(90deg);
  margin-left: 6px;
}


.reviewOptionListContainer {
  @extend .fullFlex;
  overflow-y: auto;
}

.filterOptionsPreview {
  padding: 8px 0;
  border-top: 1px solid $gray200;
}

.optionsPreviewCard {
  background-color: $gray900;
  height: 33px;
  padding: 0 16px;
  border-radius: 9999px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: white;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.optionPreviewSwiperSlide {
  width: auto !important;
}

.optionPreviewCloseIcon {
  width: 17px;
  height: 17px;
  margin-left: 4px;
}
