@import "../../../styles/color.module";
@import "../../../styles/utils.module";

.root {
  height: 169px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  & > *+* {
    margin-left: 24px;
  }
  position: relative;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  align-items: center;
  color: $gray800;
  & > *+* {
    margin-top: 6px;
  }
}

.clipboard {
  background-color: transparent;
  border: none;
  padding: 0;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;

  & > path {
    fill: black;
  }
}
