@import "styles/utils.module";
@import "styles/color.module";

.container {
  display: block;
  line-height: 0;
  @extend .cursorPointer;
}

.icon {
  margin-right: 8px;
  vertical-align: middle;
  width: 24px;
  height: 24px;
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  vertical-align: middle;
  display: inline-block;
}

@include mobile {
  .icon {
    width: 20px;
    height: 20px;
  }

  .text {
    font-size: 13px;
  }
}
