@import "styles/color.module";
@import "styles/utils.module";

.root {
  position: relative;
}

.speechBubbleWrapper {
  width: 137px;
  text-align: center;
  position: absolute;
  bottom: calc(100% + 4px);
  left: 50%;
  transform: translateX(-30%);
  margin-top: -4px;
}

.bubbleContent {
  font-size: 13px;
  line-height: 100%;
  color: $brand;
  font-weight: 600;
}
