@import "styles/utils.module";

$scrollMarginDesktop: 160px;
$scrollMarginTablet: $scrollMarginDesktop;
$scrollMarginMobile: 100px;

.infoSection {
  width: 100%;
  scroll-margin: $scrollMarginDesktop;
}

@include tablet {
  .infoSection {
    scroll-margin: $scrollMarginTablet;
  }
}

@include mobile {
  .infoSection {
    scroll-margin: $scrollMarginMobile;
  }
}


:export {
  scrollMarginDesktop: $scrollMarginDesktop;
  scrollMarginTablet: $scrollMarginTablet;
  scrollMarginMobile: $scrollMarginMobile;
}
