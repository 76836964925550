@import "styles/color.module";
@import "styles/utils.module";


.containerType2 {
  @extend .flexCol;
  @extend .justifyContentSpaceBetween;
  flex: 1;
  min-height: 0;
}

.selector {
  padding: 20px;
  border: 2px solid $tableBorder;
  width: 100%;
  font-size: 18px;
  line-height: 100%;
  font-weight: 500;
  flex: 1;
  border-radius: 5px;
  margin-bottom: 12px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .cursorPointer;
  position: relative;
}

.result {
  position: relative;
  padding: 16px;
  background-color: $lightBackground;
  border: 1px solid $tableBorder;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 12px;
  @extend .justifyContentCenter;
  @extend .flexCol;
}

.result:last-of-type {
  margin-bottom: 0;
}

.optionNameRow {
    @extend .flexRow;
    @extend .alignItemsCenter;
    @extend .justifyContentSpaceBetween;
}

.selectorContainer {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  margin-top: 12px;
}

@include tablet {
  .selectorContainer {
    margin-top: 8px;
  }
}

.counterButton {
  padding: 6px;
  background-color: $border;
  border-left: 1px solid $tableBorder;
  border-right: 1px solid $tableBorder;
  border-top: 0;
  border-bottom: 0;
  display: inline-block;
  @extend .cursorPointer;
}

.selectionCount {
  display: inline-block;
  background-color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: $typo2;
}

.buttonContainer {
  width: 122px;
  background-color: white;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  border-top: 1px solid $tableBorder;
  border-bottom: 1px solid $tableBorder;
  margin-right: 12px;
}

.optionText {
  color: $typo3;
  font-size: 18px;
  line-height: 120%;
  font-weight: 500;
  display: block;
}

@include tablet {
  .optionText {
    font-size: 14px;
  }
}

.priceTextWrapper {
  display: flex;
  align-items: center;
}

.discountRateText {
  color: $brand;
  font-size: 16px;
  line-height: 100%;
  font-weight: 800;
  margin-right: 6px;
}

.price {
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: $gray800;
  vertical-align: middle;
}

.won {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: $gray800;
  vertical-align: middle;
}

.resultContainer {
  //max-height: 490px;
}

@include tablet {
  .resultContainer {
    max-height: 240px;
  }
}


.bottomContainer {
}

@include tablet {
  .bottomContainer {
    margin-top: 32px;
  }
}

.totalPriceContainer {
  height: 21px;
}

.totalPrice {
  font-size: 22px;
  vertical-align: middle;
}

.totalWon {
  font-size: 18px;
  vertical-align: middle;
}

@include tablet {
  .totalPrice {
    font-size: 20px;
  }

  .totalWon {
    font-size: 16px;
  }
}

.close {
  margin-left: 8px;
  vertical-align: middle;
  @extend .cursorPointer
}

.floatingClose {
  @extend .cursorPointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
}

.sum {
  color: $gray800;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
}

@include tablet {
  .sum {
    font-size: 14px;
  }

  .close {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
}

.orderContainer {
  margin-top: 24px;
  @extend .flexRow;
}

@include tablet {
  .orderContainer {
    margin-top: 12px;
  }
}

@include mobile {
  .orderContainer {
    margin-top: 18px;
  }
}

.button {
  border-radius: 5px;
  height: 74px;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  @extend .cursorPointer;
}

@include tablet {
  .button {
    height: 52px;
    font-size: 16px;
  }
}

@include mobile {
  .button {
    height: 48px;
  }
}

.likeButtonContainer {
  @extend .button;
  width: 68px;
  height: 68px;
  border: 1px solid $tableBorder;
}

.likeButton {
  width: 28px;
  height: 28px;
}

.pouchButton {
  @extend .button;
  flex: 1;
  border: 1px solid $brand;
  color: $brand;
  margin-left: 10px;
  height: 68px;
  margin-right: 10px;
}

@include tablet {
  .pouchButton {
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.pouchButtonType2 {
  height: 56px;
}

.pouchButtonType3 {
  height: 48px;
  font-size: 16px;

  &:first-of-type {
    margin-left: 0;
  }
}

.likeButtonContainerType2 {
  height: 56px;
  width: 56px;
}

.buyButtonWrapper {
  flex: 1;
}

.buyButtonType2 {
  height: 56px;
}

.optionList {
  width: 100%;
  flex: 1;
  position: absolute;
  left: -2px;
  top: 100%;
  border: 2px solid $tableBorder;
  box-sizing: content-box;
  border-top-color: $brand;
}

.selectActivated {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: $brand;
  border-color: $brand;
  z-index: 99;
}

.optionElement {
  @extend .justifyContentSpaceBetween;
  @extend .flexRow;
  @extend .alignItemsCenter;
  padding: 14px 20px;
  height: 74px;
  background-color: white;
  z-index: 999;
}

.optionName {
  font-size: 18px;
  line-height: 100%;
  color: $typo3;
}

.optionPrice {
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

.optionWon {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

.modalButtonContainer {
  @extend .flexRow;
}

.modalButton {
  flex: 1;
  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }
}

.modalContainer {
  padding: 40px 24px;
}

.pouchDoneText {
  font-weight: 500;
  font-size: 16px;
  color: $typo4;
  line-height: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.pouchDonePouchText {
  font-weight: bold;
}

.soldOutText {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo1;
}

@include tablet {
  .pouchDoneText {
    font-size: 15px;
  }
}

@include mobile {
  .modalButtonContainer {
    flex-direction: column;
  }
  .modalButton {
    margin-right: 0;
    margin-bottom: 6px;
    margin-top: 6px;
  }
  .modalContainer {
    padding: 24px 20px 0 20px;
  }

  .pouchDoneText {
    margin-bottom: 24px;
  }
}


.noOptionText {
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
}

@include mobile {
  .noOptionText {
    font-size: 14px;
  }
}

.purchaseInfoContainer {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 36px;
  & > *+* {
    margin-left: 8px;
  }
  margin-bottom: 6px;
}

.bodyContainer {
  padding: 0 16px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.giftInfoContainer {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: $gray800;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  text-align: center;
}

.giftAdditionalInfo {
  margin-top: 12px;
  justify-content: start;
  text-align: left;
}
