@import "styles/utils.module";
@import "styles/color.module";


.container {
  width: 100%;
  @extend .flexRow;
  @extend .alignItemsCenter;
  height: 140px;
}

.countContainer {
  @extend .flexRow;
  width: 454px;
  margin-right: 29px;
}

.circleContainer {
  border: 1px solid $tableBorder;
  border-radius: 9999px;
  width: 56px;
  height: 56px;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
}

.orderCountUnit {
  @extend .flexRow;
}

.orderCountLabelWrapper {
  width: 70px;
  text-align: center;
  @extend .cursorPointer;
}

.link {
  flex: 1;
  border-top: 1px solid $tableBorder;
  margin-top: 28px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  margin-top: 20px;
  display: block;
}

.otherBox {
  flex: 1;
  background-color: $lightBackground;
  height: 76px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
}

.highlightedCircle {
  border-color: $brand;
}

.verticalBorder {
  height: 50px;
  border-right: 1px solid $tableBorder;
  width: 0;
}

.additionalStateCount {
  text-align: center;
  flex: 1;
}

.additionalCount {
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
  margin-left: 20px;
  vertical-align: middle;
  display: inline-block;
}

.additionalState {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  vertical-align: middle;
  //display: block;
}

@include desktop {
  .container {
    flex-direction: column;
    height: unset;
  }

  .circleContainer {
    width: 62px;
    height: 62px;
  }

  .otherBox {
    width: 100%;
    margin-top: 28px;
    //margin-bottom: 40px;
    height: 62px;
    flex: none;
  }

  .link {
    margin-top: 31px;
  }

  .orderCountLabelWrapper {
    width: 88px;
  }

  .verticalBorder {
    height: 38px;
  }

  .countContainer {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .additionalCount {
    margin-left: 16px;
    font-size: 22px;
  }

  .circleContainer {
    font-size: 22px;
  }
}


@include mobile {
  .container {
    padding: 24px 16px;
  }

  .label {
    font-size: 13px;
  }

  .otherBox {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 0;
    height: 40px;
    flex: none;
  }

  .link {
    margin-top: 20px;
  }

  .orderCountLabelWrapper {
    width: 66px;
    white-space: nowrap;
  }

  .verticalBorder {
    height: 24px;
  }

  .countContainer {
    margin-left: 0;
    margin-right: 0;
    //padding: 0 7px;
    width: 100%;
  }

  .additionalCount {
    margin-left: 8px;
    font-size: 14px;
  }

  .additionalState {
    font-size: 14px;
  }

  .circleContainer {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}
