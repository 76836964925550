@import "styles/color.module.scss";
@import "styles/utils.module.scss";

.banner {
    height: $topBannerHeight;
    background-color: black;
    overflow: hidden;
    display: block;
    z-index: 10;
}

.webBanner {
    height: $topBannerWebHeight;
    background-color: black;
    //border-bottom: 1px solid $gray300;
    @extend .flexRow;
    @extend .justifyContentSpaceBetween;
    @extend .alignItemsCenter;
    padding: 0 16px;
    @extend .cursorPointer;
}

.appIcon {
    width: 40px;
    height: 40px;
    background-image: url(https://d1cnx04b8cgzcv.cloudfront.net/media/hemeko_app_icon_small_v2.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 12px;
    border: 1px solid $gray700;
    border-radius: 8px;
}

.openApp {
    width: 93px;
    height: 30px;
    background-color: $brand;
    border-radius: 5px;
    @extend .flexRow;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
    color: white;
    font-size: 14px;
    line-height: 100%;
    font-weight: 700;
}

.appTextContent {
    margin-top: 4px;
    font-size: 13px;
    line-height: 100%;
    font-weight: 600;
    color: white;
    &:first-of-type {
        margin-top: 0;
    }
}

.leftAppContainer {
    @extend .flexRow;
    @extend .alignItemsCenter;
}

.closeIcon > path {
    fill: white;
}

.closeIcon {
    @extend .cursorPointer;
}

.content {
    @extend .alignItemsCenter;
    height: 100%;
    max-width: 1256px;
    width: 100%;
    margin: auto;
    padding: 0 $layoutRightPadding 0 $layoutLeftPadding;
}

.bannerText {
    font-size: 14px;
    font-weight: bold;
    color: white;
    line-height: 100%;
}

.bannerTextHighlight {
    font-size: 16px;
    color: $brand;
    margin-left: 6px;
}
