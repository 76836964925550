@import "styles/utils.module";
@import "styles/color.module";


.container {
  padding: 0 20px;
}

.topTabBarContainer {
  @extend .flexRow;
  margin-top: 60px;
}

.topTabBarWrapper {
  width: 560px;
}

.topTabBarMargin {
  flex: 1;
  border-bottom: 2px solid $border;
}

@include desktop {
  .topTabBarContainer {
    margin-top: 40px;
  }
}


@include tablet {
  .container {
    padding: 0 20px;
  }

  .topTabBarWrapper {
    width: 100%;
  }
}

@include mobile {
  .container {
    padding: 0 0;
  }

  .topTabBarContainer {
    margin-top: 24px;
    margin-bottom: 4px;
  }
}

.orderStatusWrapper {}

@include mobile {
  .orderStatusWrapper {
    padding: 0 20px;
  }
}

.error {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: $typo3;
  margin-top: 60px;
}

@include mobile {
  .error {
    font-size: 15px;
    margin-top: 40px;
  }
}
