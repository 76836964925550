@import "styles/color.module";
@import "styles/utils.module";

.title {
  color: $brand;
  font-weight: bold;
  font-size: 28px;
  line-height: 100%;
}

.content {
  line-height: 130%;
  color: $typo3;
  font-weight: 500;
  font-size: 18px;
}