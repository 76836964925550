@import "styles/color.module";
@import "styles/utils.module";

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand;
  & > *+* {
    margin-left: 4px;
  }
  border: none;
  height: 32px;
  width: 86px;
  border-radius: 30px;
}

.productOpenButtonContainer {
  @extend .buttonContainer;
  background-color: $gray900;
  width: 142px;
  height: 42px;
}

.subscribedButtonContainer {
  background-color: $gray200;
}

.icon {
  width: 16px;
  height: 16px;

  & > path {
    fill: white;
  }
}

.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: white;
}

.productOpenText {
  @extend .text;
  font-size: 14px;
}

.subscribedText {
  color: $gray500;
}
