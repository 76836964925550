@import "styles/color.module";
@import "styles/utils.module";


.counterButton {
  border: none;
  border-radius: 9999px;
  @extend .cursorPointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $border !important;
}

.newCounterButton {
  background-color: rgba(0,0,0,0) !important;
}

//.counterButton:disabled {
//
//}

.darkBackground {
  background-color: rgba($tableBorder, 0.3) !important;
}

.selectionCount {
  display: flex;
  text-align: center;
  font-weight: 500;
  color: $typo2;
  font-size: 14px;
  line-height: 100%;
  border: 1px solid $tableBorder;
  border-radius: 5px;
  width: 37px;
  height: 26px;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  overflow: hidden;
  border-radius: 5px;
}

.transparentBackground {
  color: $typo1;
  background-color: rgba(white, 0);
}

.svg {
  display: block;
}

.svgNew {
  & path {
    fill: $gray800;
  }
}

.svgNewInactive {
  & path {
    fill: $gray300;
  }
}

.newInput {
  flex: 1;
  color: $gray800;
  border: none;
}

.newButtonContainer {
  height: 32px;
  border: 1px solid $gray300;
  border-radius: 5px;
  padding: 0 8px;
}
