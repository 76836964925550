@import "styles/color.module";
@import "styles/utils.module";


.toastContentContainer {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}

.toastContentContent {
  flex: 1;
}

.toastContentTitle {
  line-height: 140%;
  font-weight: 600;
  font-size: 15px;
  color: white;
}

.toastContentDescription {
  font-size: 12px;
  font-weight: 500;
  color: $gray400;
}

.toastContentCloseButton {
  align-self: stretch;
  @extend .cursorPointer;
  color: $brand;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  @extend .flexRow;
  @extend .alignItemsCenter;
}
