@import "styles/color.module";
@import "styles/utils.module";

.infoSubTitle {
  color: $brand;
  margin-left: 4px;
}

.arrowRight {
  vertical-align: middle;
}

@include mobile {
  .arrowRight {
    width: 12px;
    height : 12px;
  }
}

.infoTitle {
  font-size: 18px;
  line-height: 100%;
  font-weight: bold;
  display: inline-block;
  color: $gray800;
}

.linkContainer {
  line-height: 0;
}

.linkWrapper {
  //line-height: 0;
  vertical-align: middle;
}

.postLink {
  text-decoration: underline;
  font-size: 16px;
  line-height: 100%;
  font-weight: bold;
  display: inline-block;
  color: $brand;
  vertical-align: middle;
  margin-right: 4px;
  @extend .cursorPointer;
}

.container {
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
  padding: 0 0 20px 0;
}

@include tablet {
  .container {
    padding-left: $layoutLeftPadding;
    padding-right: $layoutRightPadding;
  }

  .postLink {
    font-size: 14px;
  }
}

@include mobile {
  .container {
    padding: 24px $layoutLeftPadding 20px $layoutRightPadding;
  }

  .postLink {
    margin-right: 2px;
  }
}
