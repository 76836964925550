@import "styles/color.module.scss";
@import "styles/utils.module";

.speechBubbleContainer {
  position: relative;
  background-color: $brand;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  color: white;
  line-height: 100%;
  font-weight: 500;
  overflow: visible;
  display: inline-block;
}

.speechBubble {
  display: block;
}

.speechBubbleTail {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
