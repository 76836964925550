@import "styles/color.module";
@import "styles/utils.module";

.shareModal {
  position: absolute;
  height: 48px;
  padding: 0 12px;
  background-color: white;
  border-radius: 5px;
  //border: 1px solid $border;
  //right: 0;
  top: 36px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  box-shadow: 0 0 6px rgba(25, 25, 25, 0.15);
  z-index: 50;
}

.tail {
  content: '';
  position: absolute;
  top: -5px;
  right: 2px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: white;
  border-bottom-width: 20px;
  border-top: 0;
  //margin-left: -15px;
  //margin-top: -5px;
  //box-shadow: 0 0 5px black ;
}


.shareModal > button {
  background: none;
  border: none;
  padding: 0;
  line-height: 0;
}

.kakaoIcon {
  height: 32px;
  width: 32px;
  margin-right: 16px;
  @extend .cursorPointer;
  z-index: 2;
}

.copyIcon {
  width: 32px;
  height: 32px;
  margin-right: 20px;
  @extend .cursorPointer;
  z-index: 2;
}

.closeIcon {
  width: 24px;
  height: 24px;
  @extend .cursorPointer;
  z-index: 2;
}
