@import "styles/utils.module";
@import "styles/color.module";


.container {}

.section {
  margin-bottom: 40px;
  width: 335px;
}

.sectionTitle {
  margin-bottom: 20px;
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
}

.sectionSubtitle {
  display: block;
  font-size: 16px;
  line-height: 120%;
  color: $typo2;
  font-weight: 500;
  margin-bottom: 8px;
}

.content {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 120%;
  color: $typo3;
}

.snsInfoContainer {
  margin-top: 12px;
}

.snsLogo {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 6px;
}

.snsType {
  vertical-align: middle;
  color: $typo2;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.snsDescription {
  font-size: 14px;
  line-height: 140%;
  color: $typo2;
  font-weight: 500;
  display: block;
  margin-top: 4px;
}

.editorContainer {
  margin-top: 24px;
  width: 100%;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}

.editorContainerActive {
  @extend .flexCol;
  @extend .alignItemsStart;
}

.editorButtonWrapper {
  width: 120px;
}

.buttonsContainer {
  @extend .flexRow;
}

.submitButtonWrapper {
  flex: 1;
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0;
  }
}

.bulletTextWrapper {
  margin-top: 12px;
}

.selfVerificationSection {
  margin-bottom: 24px;
}

.agreementCheckboxWrapper {
  display: inline-block;
  margin-right: 16px;
  &:last-of-type {
    margin-right: 0;
  }
}

.agreementCheckboxText {
  font-size: 15px;
  line-height: 100%;
  color: $typo3;
  font-weight: 500;
}

.agreementWarning {
  display: block;
  line-height: 140%;
  font-size: 15px;
  color: $typo2;
  font-weight: 500;
  margin-top: 24px;
}

.agreementSeeMore {
  text-decoration: underline !important;
  margin-top: 8px;
  display: inline-block;
  color: $typo1;
  line-height: 100%;
  font-size: 14px;
  font-weight: 500;
  @extend .cursorPointer;
}

.withdrawContainer {
  margin-top: 60px;
}


.withdrawQuestion {
  font-size: 16px;
  line-height: 120%;
  color: $typo3;
  font-weight: 500;
  margin-right: 24px;
  vertical-align: middle;
}

.withdrawLink {
  text-decoration: underline !important;
  color: $typo1;
  line-height: 100%;
  font-size: 14px;
  font-weight: 500;
  @extend .cursorPointer;
  display: inline-block;
  vertical-align: middle;
}


@include tablet {
  .container {
    padding: 0 20px;
  }

  .sectionTitle {
    font-size: 18px;
  }

  .sectionSubtitle {
    font-size: 15px;
  }

  .content {
    font-size: 15px;
  }

  .agreementWarning {
    font-size: 15px;
  }

  .withdrawContainer {
    margin-top: 40px;
  }

  .withdrawQuestion {
    font-size: 15px;
  }
}


@include mobile {
  .container {
    padding: 0;
  }

  .section {
    padding: 0 20px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .sectionTitle {
    font-size: 17px;
  }

  .snsDescription {
    font-size: 13px;
  }

  .withdrawContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    padding: 0 20px;
  }

  .editorButtonWrapper {
    width: 105px;
  }

  .agreementCheckboxText {
    font-size: 14px;
  }

  .agreementWarning {
    font-size: 13px;
  }

  .agreementSeeMore {
    font-size: 13px;
  }

  .withdrawLink {
    font-size: 13px;
  }
}

.profileImageEditor {
  @extend .alignItemsCenter;
}

.profileImage {
  width: 72px;
  height: 72px;
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  margin-right: 0;
  margin-bottom: 16px;
}

.profileImageHorizontal {
  width: 62px;
  height: 62px;
  margin-bottom: 0;
  margin-right: 12px;
}

.profileImageDelete {
  background-color: rgba($typo3, 0.35);
  height: 24px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.profileImageBorder {
  border: 1px solid $tableBorder;
}

.profileNicknameContainer {
  @extend .flexCol;
}

.profileNicknameContainerHorizontal {
  @extend .alignItemsStart;
}

.profileNicknameWrapper {
  line-height: 0;
  margin-bottom: 12px;
  text-align: center;
}

.profileNicknameWrapperHorizontal {
  margin-bottom: 8px;
}

.profileNickname {
  font-size: 22px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
  vertical-align: middle;
}

.profileNicknameHorizontal {
  font-size: 18px;
}

.profileNicknameArrow {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 4px;
}

.profileEditText {
  font-size: 15px;
  font-weight: bold;
  line-height: 120%;
  color: $brand;
  text-decoration: underline;
  position: relative;
  text-align: center;
}

.imageInputInvisible {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @extend .cursorPointer;
  opacity: 0;
}
