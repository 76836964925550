@import "styles/utils.module";
@import "styles/color.module";



.gridRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;  // gap is usable in display: grid.
}

.imageWrapper {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  @extend .no-select;
  isolation: isolate;
}

.reviewModalTitle {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  padding: 0 16px;
  height: 50px;
  background-color: white;
}

.reviewModalTitleText {
  flex: 1;
  text-align: center;
}

.arrowLeftIcon {
  width: 24px;
  height: 24px;

  & > path {
    fill: black;
  }
}


.reviewModalContentWrapper {
  position: relative;
  width: 100%;

  overscroll-behavior-x: none;
}


.reviewModalContentItem {
  width: 100%;
  height: 100%;
  position: absolute;
}


.productImageSwiperHorizontalClass {
  bottom: 12px !important;
  line-height: 0 !important;
}

.productImageSwiperBulletClass {
  border-radius: 9999px;
  background-color: $lightBackground !important;
  //background-color: green !important;
  display: inline-block;
  width: 6px;
  height: 6px;
  line-height: 0;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
}

.productImageSwiperBulletActiveClass {
  border-radius: 9999px;
  background-color: $brand !important;
  //background-color: green !important;
  display: inline-block;
  width: 6px;
  height: 6px;
  line-height: 0;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
}

.reviewModalGoToGrid {
  position: sticky;
  bottom: 0;
  padding: 12px 16px calc(env(safe-area-inset-bottom) + 10px);
  z-index: 10;
  background-color: white;
}
