@import "styles/color.module";
@import "styles/utils.module";

.container {
  top: 0;
  //left: 0;
  right: 0;
  bottom: 0;
  //width: 100%;
  //height: 100%;
  overflow: hidden;
  position: fixed;
  background-color: rgba($typo4, 0.4);
  z-index: 100000;
  @extend .flexCol;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  max-width: $mobileMaxWidth;
  left: 50%;
  transform: translate(-50%, 0);
}

.noBackgroundBlur {
  background-color: transparent;
}

.bottom {
  @extend .justifyContentEnd;
}

.left {
  @extend .alignItemsStart;
}

.contentContainer {
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
}

.showOverflow {
  overflow-y: visible;
}

@include mobile {
  .contentContainer {
    border-radius: 0;
  }
}

.noRound {
  height: 100%;
  border-radius: 0;
}

.round {
  border-radius: 5px !important;
}

.topRound {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.bottomSheetContainer {
  & > div[data-rsbs-backdrop="true"] {
    //z-index: 1000000000;
    z-index: 100000;
  }

  & > div[aria-modal="true"] {
    //z-index: 1000000000;
    z-index: 100000;
    max-width: $mobileMaxWidth;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
  }
}

.sheetModalBackdrop {
  position: fixed;
  background-color: rgba($typo4, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: opacity .2s linear, z-index .2s linear;
}
