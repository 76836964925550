@import "styles/color.module";
@import "styles/utils.module";

.otherModalContainer {
  padding: 24px 16px 24px;
}

.root {
  @extend .fullWidth;
  text-align: center;
  padding-top: 50px;
}


.containerWithoutForm {
  max-width: 600px;
  padding: 100px 16px 0 16px;
  width: 100%;
  position: relative;
  flex: 1;
  margin: auto;
  @extend .flexCol;
  @extend .justifyContentSpaceBetween;
}

.buttonSectionWithoutForm {
  padding-bottom: 48px;
}


.rootWithoutForm {
  @extend .fullWidth;
  min-height: 100vh;
  padding-bottom: calc(env(safe-area-inset-bottom));
  @extend .flexCol;
}


.otherLoginWithoutForm {
  cursor: pointer;
  text-align: center;
  margin-bottom: 104px;
  margin-top: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
  position: relative;
}

.previousWebsiteInfoContainer {
  font-size: 12px;
  line-height: 120%;
  color: $gray800;
  font-weight: 500;
}

.previousWebsiteInfoLink {
  color: $brand;
}

.contactInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 14px;
  line-height: 140%;
  color: $gray800;
  font-weight: 500;

  margin-top: 40px;
}

.loginHelpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;
  margin-top: 4px;
}

.loginHelpContainerMail {
  color: $brand;

  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;

  background: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 0;
}


.snsWrapperWithoutForm {
  width: 100%;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.goHomeButton {
  @extend .cursorPointer;
  display: block;
  @extend .textAlignCenter;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  margin-top: 56px;
  color: $gray400;
}


.lastLoginBubble {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.lastLoginBubbleText {
  color: white;
  font-weight: 600;
  line-height: 13px;
  display: block;
  font-size: 13px;
}

.bubbleWrapper {
  display: inline-block;
  position: relative;
  top: -13px;
  overflow: visible;
  left: 50%;
  transform: translate(-50%, 0);
}

.bubbleText {
  font-size: 13px;
  font-weight: 600;
  color: white;
  line-height: 100%;
  display: block;
}
