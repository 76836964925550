@import "styles/utils.module.scss";
@import "styles/color.module";

@each $name, $map in $themes {
    .theme-#{$name} {
        @each $key, $value in $map {
            --#{$key}: #{$value};
        }
    }
}

.infoContainer {
    margin-top: 8px;
}

.verticalInfoContainer {
    margin-top: 12px !important;
    margin-left: 12px;
    margin-right: 12px;
}

.textPaddingType_medium {
    padding: 4px 12px 0 12px;
}

.textPaddingType_small {
    padding: 0 8px;
}

.textPaddingType_none {
    padding: 0;
}

.brandSection {
    @extend .flexRow;
    @extend .justifyContentSpaceBetween;
    @extend .alignItemsCenter;
    //margin-bottom: -2px;
}

.brand {
    font-size: 11px;
    line-height: 100%;
    font-weight: 600;
    color: var(--typo2);
    @extend .no-select;
}

.colorCount {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    display: block;
    color: var(--typo2);
    @extend .no-select;
}

.name {
    color: var(--gray800);
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    //word-break: keep-all;
    //word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 8px;
    margin-top: 4px;
    @extend .no-select;
}

.enName {
    @extend .name;
    word-break: keep-all;
}

.optionName {
    color: $gray800;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    //word-break: keep-all;
    //word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 8px;
    margin-top: 2px;
    @extend .no-select;
}

.discount {
    line-height: 100%;
    color: var(--brand);
    font-weight: 700;
}

.percentage {
    line-height: 100%;
    color: var(--brand);
    font-weight: 700;
    margin-right: 4px;
}

.discountTag {
    font-size: 10px;
    line-height: 100%;
    color: var(--brand);
    margin-right: 4px;
    font-weight: 700;
}

.couponDiscountTag {
    color: var(--emphasis);
}

.fontStyle {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: var(--brand);
    @extend .no-select;
}

.discountedPriceContainer {
    margin-top: 4px;
    display: flex;
    font-size: 14px;
    align-items: flex-end;
}

.itemContainer {
    display: flex;
    align-items: center;
}

.itemPriceContainer {
    flex: 1;
}

.price {
    color: var(--typo4);
    font-weight: 800;
}

.originalPriceContainer {
    display: flex;
    align-items: center;
    color: var(--typo1);
    font-size: 10px;
    font-weight: 500;
    line-height: 100%;
    margin-left: 0;
    margin-bottom: 2px;
}

.originalPrice {
    text-decoration: line-through;
}

.likeContainer {
    position: absolute;
    right: 8px;
    bottom: 8px;
    z-index: 2;
}

.miniScrapIcon {
    right: 8px;
    bottom: 8px;
}

.addToPouchIcon {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.rankIcon {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 2;
}

@include mobile {

    .rankIcon {
        left: 8px;
        top: 8px;
    }
}

.likeIcon {}

//@include mobile {
//    .likeIcon {
//        width: 20px;
//        height: 20px;
//    }z
//}

.overlay {
    // TODO: Fill has EDEDED?
    background: rgba(#000, 0.4);
    height: 100%;
    width: 100%;
    z-index: 4;
    position: absolute;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
}

.rank {
    color: var(--brand);
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
    margin-bottom: 10px;
}

.lowRank {
    color: var(--typo3);
}

.tagsContainer {
    margin-top: 10px;
    @extend .noLineHeight;
}

.myPriceTagContainer {
    border-radius: 2px;
    background-color: var(--lightBackground2);
    padding: 3px 4px;
    margin-bottom: 4px;
    @extend .flexRow;
    @extend .alignItemsCenter;
}

.myPriceText {
    font-size: 10px;
    font-weight: 500;
    line-height: 100%;
    color: var(--typo3);
    margin-left: 2px;
}

.tagWrapper {
    background-color: white;
    border-radius: 2px;
    border: 1px solid var(--lightBackground2);
    width: fit-content;
    padding: 3px 4px;

    line-height: 0;
    display: inline-block;
    margin-right: 4px;
    &:last-of-type {
        margin-right: 0;
    }
}

.tag {
    color: var(--typo2);
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    vertical-align: middle;
}

.descriptionWrapper {
    display: inline-block;
    height: 18px;
    margin-top: 8px;
    background-color: var(--gray100);
    border-radius: 3px;
    padding: 4px 8px;

    span {
        display: flex;
        font-size: 10px;
        font-weight: 500;
        line-height: 100%;
        color: var(--gray800);
    }
}

.eventTag {
    color: var(--emphasis2);
}

.daysLeftTag {
    color: white;
    font-size: 10px;
}

.daysLeftTagWrapper {
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: var(--emphasis2);
    z-index: 5;
    border: none;
}

@include mobile {
    .tag {
        font-size: 10px;
    }
}

.bottomNumOptions {
    margin-top: 8px;
}

.addToPouchButton {
    margin-top: 12px;
}

.reviewInfoContainer {
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 100%;
    width: 100%;
}

.reviewScore {
    color: var(--gray400);
    font-size: 11px;
    margin-left: 4px;
}

.scoreText {
    font-size: 11px;
    font-weight: 600;
    line-height: 100%;
    color: var(--gray400);
}

.reviewCount {
    color: var(--gray400);
    font-size: 11px;
    font-weight: 600;
    margin-left: 4px;
}

.adminHashButton {
    position: absolute;
    z-index: 10;
    top: 8px;
    right: 8px;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    background-color: black;
    border-radius: 9999px;
    color: white;
}

.hashTagModalContainer {
    width: 340px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.hashTagResult {
    @extend .newSidePadding;
    @extend .flexRow;
    flex-wrap: wrap;
    padding: 6px 12px 0 12px;
    border-top: 1px solid var(--border);
}

.overLay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    //background-color: rgba(0, 0, 0, 0.4);
    @extend .flexRow;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
}

.hashTag {
    color: white;
    font-weight: 600;
    line-height: 100%;
    font-size: 13px;
    background-color: var(--brand);
    margin-right: 8px;
    padding: 4px 4px 4px 12px;
    border-radius: 9999px;
    margin-bottom: 6px;
    @extend .flexRow;
    @extend .alignItemsCenter;
}

.hashTagClose {
    & path {
        fill: white;
    }
    margin-left: 6px;
}

.searchContainer {
    border-bottom: 1px solid var(--border);
}

.searchInput {
    height: 44px;
    color: $gray900;
    font-size: 14px;
    line-height: 100%;
    width: 100%;
    border: none;
    padding: 0 12px;
}

.resultContainer {
    //min-height: 200px;
    //max-height: 400px;
    overflow-y: auto;
}

.resultRowContainer {
    @extend .flexRow;
    @extend .alignItemsCenter;
    border-bottom: 1px solid var(--lightBackground2);
    cursor: pointer;
    @extend .fullHeight;
    padding: 0 12px;
    font-weight: 600;
    font-size: 14px;
    &:hover {
        background-color: rgba(var(--brand), 0.5);
        border-bottom: 1px solid rgba(var(--brand), 0.5);
        color: white;
    }
}

.registeredResultRowContainer {
    background-color: var(--brand);
    color: white;
    border-bottom: 1px solid var(--brand);
}

.recentTagContainer {
    padding: 6px 12px;
    margin-bottom: 12px;
}

.recentTagTitle {
    font-size: 15px;
    font-weight: 600;
}

.recentTag {
    @extend .hashTag;
    border: 1px solid var(--border);
    background-color: white;
    color: var(--gray800);
    padding: 12px;
}

.recentTagsContainer {
    margin-top: 12px;
    @extend .flexRow;
    flex-wrap: wrap;
}

.newRank {
    position: absolute;
    left: 8px;
    top: 0;
    z-index: 2;
    width: 22px;
    height: 22px;
    @extend .flexRow;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
    background-color: var(--brand);
    color: white;
    font-size: 12px;
    line-height: 100%;
    font-weight: 700;
}

.priceUnit {
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    color: var(--gray800);
    margin-left: 2px;
}

.lowRankV2 {
    background-color: var(--gray900);
}
