@import "styles/color.module";
@import "styles/utils.module";

.root {
  @extend .fullWidth;
  text-align: center;
  padding-top: 50px;
}

.rootWithoutForm {
  @extend .fullWidth;
  min-height: 100vh;
  padding-bottom: calc(env(safe-area-inset-bottom));
  @extend .flexCol;
}

.container {
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding: 50px 16px 0;
}

.logo {
  display: block;
  margin-bottom: 26px;
}

.inputWrapper {
  margin-top: 12px;
  &:first-of-type {
    margin-top: 0;
  }
}

.buttonWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.checkbox {
  vertical-align: middle;
  margin-right: 4px;
}

.autoLogin {
  vertical-align: middle;
  font-size: 14px;
  color: $typo2;
  font-weight: 500;
  line-height: 100%;
  @extend .cursorPointer;
}

.find {
  vertical-align: middle;
  color: $gray600;
  font-weight: 500;
  line-height: 100%;
  @extend .cursorPointer;
}

.pipe {
  vertical-align: middle;
  margin: 0 8px;
}

.findContainer {
  margin-bottom: 60px;
}

.snsText {
  font-size: 16px;
  color: $typo2;
  font-weight: bold;
  line-height: 100%;
  margin-bottom: 24px;
}

.snsWrapper {
  display: inline-block;
  margin-right: 28px;
  &:last-of-type {
    margin-right: 0;
  }
}

.border {
  height: 1px;
  background-color: $border;
  margin: 40px 0;
}

.registerText {
  margin-top: 16px;
  margin-bottom: 48px;
  color: $typo3;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
}

.coupon {
  color: $brand;
  font-weight: bold;
}

.nonMemberDelivery {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
  vertical-align: middle;
}

.arrow {
  vertical-align: middle;
  margin-left: 2px;
}


@include mobile {
  .logo {
    width: 133px;
    height: 24px;
  }

  .find {
    font-size: 12px;
  }

  .autoLogin {
    font-size: 13px;
  }

  .snsText {
    font-size: 15px;
  }

  .registerText {
    font-size: 14px;
  }

  .nonMemberDelivery {
    font-size: 13px;
  }
}


.modalContentContainer {
  padding: 0 40px 40px 40px;
}

.titleBarWrapper {
  margin: 24px 24px;
}

@include mobile {
  .titleBarWrapper {
    margin: 0 20px;
  }

  .modalContentContainer {
    padding: 24px 20px 20px 24px;
  }
}

.snsRegister {
  font-size: 16px;
  font-weight: 500;
  color: $typo2;
  line-height: 100%;
  display: block;
}

.snsButtonContainer {
  margin-top: 24px;
}

.orContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin: 40px 0 40px 0;
}

.orPadding {
  flex: 1;
  height: 1px;
  background-color: $border;
}

.orText {
  display: block;
  margin: 0 20px 0 20px;
  font-weight: 500;
  font-size: 13px;
  color: $typo1;
  line-height: 100%;
}

@include mobile {
  .orText {
    font-size: 12px;
  }

  .snsRegister {
    font-size: 15px;
  }
}

.findOrder {
  @extend .cursorPointer;
}

.containerWithoutForm {
  max-width: 600px;
  padding: 136px 16px 0 16px;
  width: 100%;
  position: relative;
  flex: 1;
  margin: auto;
  @extend .flexCol;
  @extend .justifyContentSpaceBetween;
}

.snsWrapperWithoutForm {
  width: 100%;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.titleWrapperWithoutForm {
  margin-bottom: 70px;
  @extend .noLineHeight;
  @extend .textAlignCenter;
}

.titleWithoutForm {
  line-height: 140%;
  margin: 0;
  color: $gray800;
  font-size: 24px;
  font-weight: 600;
}

.titleWithoutFormBold {
  font-weight: bold;
  color: $brand;
}

.subTitleWithoutForm {
  font-size: 14px;
  line-height: 140%;
  font-weight: 500;
  color: $gray800;
  margin-top: 12px;
}

.separator {
  border-top: 1px solid $gray200;
  width: 100px;
}

.separatorContent {
  margin: 0 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: $gray500;
}

.buttonsContainerWithoutForm {
  margin-bottom: 0;
  position: relative;
}

.emailButtonWithoutForm {
  margin-top: 8px;
  position: relative;
}

.goHomeButton {
  @extend .cursorPointer;
  display: block;
  @extend .textAlignCenter;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: $gray400;
}

.bubbleWrapper {
  display: inline-block;
  position: relative;
  top: -13px;
  overflow: visible;
  left: 50%;
  transform: translate(-50%, 0);
}

.bubbleText {
  font-size: 13px;
  font-weight: 600;
  color: white;
  line-height: 100%;
  display: block;
}

.separatorWrapper {
  margin-top: 72px;
  margin-bottom: 24px;
}

.verticalSeparator {
  height: 11px;
  border-right: 1px solid $gray600;
  margin: 0 24px;
}

.separator2 {
  height: 12px;
  border-right: 1px solid $gray400;
}

.textButtonContainer {
  @extend .flexCol;
  @extend .justifyContentEnd;
}

.logoWithoutForm {
  width: 256.8px;
  height: 60px;
  margin-top: -10px;
}

//@media (max-width: 350px) {
//  .titleWithoutForm {
//    font-size: 20px;
//  }
//
//  .logoWithoutForm {
//    width: 214px;
//    height: 50px;
//    margin-top: -5px;
//  }
//}

.buttonSectionWithoutForm {
  padding-bottom: 48px;
}

.otherLoginWithoutForm {
  cursor: pointer;
  text-align: center;
  margin-bottom: 104px;
  margin-top: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
  position: relative;
}

.otherModalContainer {
  padding: 24px 16px 24px;
}

.lookAround {
  position: absolute;
  top: 40px;
  left: 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: $gray400;
}
