@import "styles/utils.module.scss";
@import "styles/color.module";

.container {
    width: 280px;
    //flex: 0 0 $navBarWidthDesktop;
    margin-left: 0;
    margin-right: 0;
    padding-left: 24px;
    border-right: 2px solid #FFFFFF;
    height: 1px;
    //background-color: #FFF4F5;
    overflow-y: auto;
    background-color: white;
    padding-bottom: 60px;
    //mask-image: linear-gradient(to bottom, white 90%, transparent 100%);
    //-webkit-mask-image: linear-gradient(to bottom, white 90%, transparent 100%);
}

.desktopNicknameContainer {
    line-height: 0;
    padding: 40px 0;
    @extend .cursorPointer;
    @extend .flexRow;
    @extend .alignItemsCenter;
}

.desktopNickname {
    font-size: 20px;
    font-weight: bold;
    line-height: 100%;
    color: $typo4;
    vertical-align: middle;
    flex-shrink: 1;
    word-break: break-all;
}

@include tablet {
    .desktopNickname {
        font-size: 18px;
    }
}

.desktopNicknameArrow {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-left: 4px;
}

.container::-webkit-scrollbar-track {
    background-color: white;
}

.container::-webkit-scrollbar {
    width: 0;
}

.nameContainer {
    padding-bottom: 24px;
    display: block;
}

.fixed {
    position: sticky;
    top: $topBarHeight;
    left: 0;
    height: 100vh;
}

@include tablet {
    .container {
        //flex: 0 0 $navBarWidthTablet;
        width: $navBarWidthTablet;
    }
}

.expandedContainer {
    width: 72px;
    min-width: 72px;
    margin-left: 0;
    margin-right: 0;
    padding-right: 24px;
    padding-left: 24px;
    border-right: 1px solid $border;
    transition: width linear 0.3s 0s;
}

.routerContainer {
    width: 100%;
    height: 100%;
    padding-top: $topPadding;
}

.mobileContainer {
    position: fixed;
    //left: 0;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: white;
    //width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: $mobileMaxWidth;
    width: 100%;
    height: calc(env(safe-area-inset-bottom) + $mobilNavBarHeight);
    z-index: 10000;
    transition: bottom 0.1s ease;
}

.mobileContainerPadder {
    width: 100%;
    min-height: 100px;
    height: 100px;
    background-color: blue;
}

.mobileContainerHidden {
    bottom: -72px;
}

.navBarVector {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.hamburgerContainer {
    position: absolute;
    background-color: $typo4;
    left: 8px;
    bottom: 10px;
    width: 59px;
    height: 59px;
    border-radius: 9999px;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
}

.itemsContainer {
    background-color: white;
    @extend .flexRow;
    height: 100%;
    width: 100%;
}

.navBarItem {
    flex: 1;
    @extend .justifyContentCenter;
    @extend .alignItemsCenter;
    @extend .flexCol;
    line-height: 120%;
    font-size: 10px;
    font-weight: bold;
    color: $brand;
    border-top: 1px solid $brand;
}

.itemIcon {
    display: block;
    margin-bottom: 1px;
}

.itemIconActive {
    & > path {
        fill: $brand;
    }

    & > circle {
        stroke: $brand;
    }
}

.itemIconInactive {
    & > path {
        fill: #757575;
    }

    & > circle {
        stroke: #757575;
    }
}

.itemTextInactive {
    color: $typo1;
    font-weight: 500;
    border-top: none;
}

.sideBarContainer {
    height: 100%;
    width: 100%;
    padding: 24px 20px;
}

.nickname {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    color: $typo3;
    display: block;
}

.level {
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    margin-top: 8px;
    color: $typo2;
    display: block;
}

.logout {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    color: $typo3;
    border: 1px solid $tableBorder;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
    @extend .cursorPointer;
    border-radius: 5px;
    height: 40px;
}

.logoutWrapper {
    margin-top: 40px;
    padding-bottom: 40px;
}

.feedbackContainer {
    padding: 40px 0;
}

.feedbackButton {
    background-color: $brand;
    height: 30px;
    @extend .justifyContentCenter;
    @extend .alignItemsCenter;
    @extend .cursorPointer;
    border-radius: 9999px;
    width: 120px;
}

.feedbackText {
    color: white;
    font-weight: bold;
    margin-left: 2px;
    line-height: 100%;
    font-size: 16px;
    display: block;
}

@include tablet {
    .feedbackText {
        font-size: 14px;
    }
    .feedbackButton {
        width: 112px;
    }
}
