@import "styles/color.module";
@import "styles/utils.module";


.container {
  background-color: $gray50;
  @extend .flexCol;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.subContainer {
  width: 100%;
  background-image: url(https://d1cnx04b8cgzcv.cloudfront.net/media/modal/app_modal_background.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $gray900;
  padding: 44px 20px 20px;
  position: relative;
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: white;
  text-align: center;
}

.couponIcon {
  width: 131px;
  height: 131px;
  background-image: url(https://d1cnx04b8cgzcv.cloudfront.net/media/modal/app_modal_coupon_icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -16px;
}

.contentContainer {
  width: 100%;
  margin-top: -3px;
}

.normalBox {
  background-color: white;
  border-radius: 10px;
  height: 58px;
  align-items: center;
  width: 100%;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  padding: 0 12px 0 15px;
  margin-top: 12px;
  &:first-of-type {
    margin-top: 0;
  }
}

.normalBoxLeftText {
  @extend .flexRow;
  @extend .alignItemsCenter;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: $gray800;
}

.smallReceiveButton {
  width: 159px;
  height: 36px;
  border-radius: 5px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  background-color: $brand;
  font-size: 13px;
  line-height: 100%;
  font-weight: bold;
  color: white;
  @extend .cursorPointer;
}

.receiveButtonInactive {
  color: $gray500;
  background-color: $gray200;
  @extend .cursorUnset;
}

.bigBox {
  padding: 16px 12px 10px 15px;
  background-color: white;
  border-radius: 10px;
  margin-top: 12px;
}

.bigBoxTitle {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
}

.bigBoxText {
  @extend .flexRow;
  @extend .alignItemsCenter;
  font-weight: 500;
  line-height: 100%;
  font-size: 12px;
  color: $gray800;
  margin-top: 4px;
  &:first-of-type {
    margin-top: 0;
  }
}

.bigIcon {
  height: 17px;
  background-color: $brand;
  border-radius: 3px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  font-size: 9px;
  line-height: 100%;
  font-weight: 700;
  color: white;
  padding: 0 5px;
  margin: 0 3px;
}

.bigBoxButtonWrapper {
  margin-top: 15px;
  @extend .flexRow;
  @extend .alignItemsCenter;
}

.bigBoxInput {
  //border: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  color: $gray800;
  border: none;
  width: 100%;
  &::placeholder {
    color: $gray400;
  }
  text-align: center;
}

.bigBoxInputWrapper {
  height: 36px;
  margin-right: 4px;
  flex: 1;
  @extend .flexRow;
  @extend .alignItemsCenter;
  border-radius: 5px;
  border: 1px solid $gray300;
}

.bigBoxButton {
  width: 151px;
}

.bulletContainer {
  margin-top: 12px;
}

//.root {
//  display: flex;
//  justify-content: center;
//  width: 100%;
//}
//
//.content {
//  display: flex;
//  flex-direction: column;
//  padding: 32px 16px 18px;
//  align-items: center;
//  border-radius: 20px;
//  width: 330px;
//  background-color: white;
//}
//
//.title {
//  font-size: 24px;
//  font-weight: 700;
//  line-height: 140%;
//  color: $gray800;
//}
//
//.image {
//  width: 180px;
//}
//
//.notice {
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//  margin-top: -13px;
//}
//
//.noticeText {
//  font-size: 12px;
//  font-weight: 500;
//  line-height: 140%;
//  color: $gray500;
//}
//
//.description {
//  font-size: 14px;
//  font-weight: 500;
//  line-height: 140%;
//  color: $gray800;
//}
//
//.couponContainer {
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//  margin-top: 12px;
//}
//
//.couponWrapper {
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//  background-color: $gray100;
//  border-radius: 5px;
//  padding: 11px 16px 11px 10px;
//  width: 100%;
//}
//
//.couponDescription {
//
//}
//
//.couponTitle {
//  font-size: 13px;
//  font-weight: 600;
//  line-height: 100%;
//  color: $gray800;
//}
//
//.button {
//  width: 155px;
//}
