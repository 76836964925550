@import "styles/bootstrap-grid.min.css";
@import "styles/color.module";
@import "styles/utils.module";

@font-face {
    font-family: SUIT-HMK;
    font-weight: 100 900;
    src:url('https://d2gq5h2gkiufuv.cloudfront.net/SUIT-Variable.woff2') format('woff2-variations');
}

button {
    font-family: SUIT-HMK !important;
}

textarea {
    font-family: SUIT-HMK !important;
}

html {
    height: 100%;
    position: relative;
    //overflow-x: hidden;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: SUIT-HMK, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    line-height: 1;
    font-size: 18px;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

* {
    box-sizing: border-box;
}

*:not(input) {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

textarea {
    -webkit-user-select: text !important; /* Safari */
    -khtml-user-select: text !important; /* Konqueror HTML */
    -moz-user-select: text !important; /* Old versions of Firefox */
    -ms-user-select: text !important; /* Internet Explorer/Edge */
    user-select: text !important; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
    display: block;
}

textarea:focus, input:focus{
    outline: none;
}

.container {
    padding: 0;
    margin: 0;
    max-width: 20000px;
}

.row {
    margin: 0;
    padding: 0;
}

.col {
    margin: 0;
    padding: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    padding: 0;
    margin: 0;
}

.col-lg-6 {
    padding: 0;
    margin: 0;
}

.col-lg-12 {
    padding: 0;
    margin: 0;
}

.col-xl-6 {
    padding: 0;
    margin: 0;
}

html, body {
    overscroll-behavior: none;
    width: 100%;
}

#__next {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 0;
}

.stickToHeaven {
    position: sticky;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 99909;
}

.stickToSky {
    position: sticky;
    left: 0;
    top: 100px;
    background-color: white;
    z-index: 1;
}

.checkoutStickySideBar {
    position: sticky;
    left: 0;
    top: 28px;
    z-index: 100;
}

th, td {
    padding: 12px 16px;
    margin: 0;
    text-align: left;
    font-weight: 500;
    color: $typo2;
    vertical-align: top;
    word-break: keep-all;
    word-wrap: break-word;
    line-height: 20px;
    font-size: 14px;
}

table, th, td {
    border: 2px solid $tableBorder;
    border-collapse: collapse;
}

table {
    margin-top: 24px;
    margin-bottom: 24px;
}

th {
    background-color: $lightBackground;
}

.control-dots { // react carousel
    margin: 0 !important;
    line-height: 0;
    text-align: left !important;
    padding: 5.0% 9% !important;
}

@include tablet {
    .control-dots {
        text-align: center !important;
        padding: 20px 0 !important;
    }
}

@include mobile {
    .control-dots {
        padding: 40px 0 !important;
    }
}

.noScroll {
    overflow: hidden !important;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

:root {
    --rsbs-backdrop-bg: rgba(25, 25, 25, 0.4);
    --rsbs-bg: #fff;
    --rsbs-overlay-rounded: 5px !important;
    --input-range-slider: 92%;
}

[data-rsbs-root]:after {
    z-index: 1000000 !important;
}

[data-rsbs-header] {
    padding: 0 !important;
    box-shadow: none !important;
}

[data-rsbs-footer] {
    padding: 0 !important;
    box-shadow: none !important;
}

[data-rsbs-header]:before {
    position: absolute;
    content: '';
    display: block;
    width: 48px !important;
    height: 4px !important;
    top: 0 !important;
    left: 50%;
    margin-top: -8px;
    transform: translateX(-50%);
    border-radius: 4px;
    background-color: #D9D9D9 !important;
}

[contenteditable] {
    outline: 0px solid transparent;
    -webkit-user-select: text !important;
    user-select: text !important;
}

/****** TOAST ******/
.Toastify {
    z-index: 99999999999 !important;
}

.Toastify__toast-container {
    max-width: $mobileMaxWidth;
    width: 100vw !important;
    z-index: 99999999999 !important;
}


.Toastify__toast-container.newToast {
    bottom: 80px !important;
}

.Toastify__toast {
    background-color: #21272A !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 140% !important;
    width: auto !important;
    min-height: 45px !important;
    padding: 0 16px 0 20px !important;
    border-radius: 5px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    @extend .flexRow;
    @extend .alignItemsCenter;
    z-index: 99999999999 !important;
    word-break: keep-all;
    word-wrap: break-word;
}

.Toastify__toast.newToastToast {
    background-color: $gray900 !important;
    color: white !important;
    width: auto !important;
    padding: 14px 20px 14px 20px !important;
    border-radius: 10px !important;
    @extend .flexRow;
    @extend .alignItemsCenter;
    z-index: 99999999999 !important;
    word-break: keep-all;
    word-wrap: break-word;
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-bottom: 0 !important;
}

.Toastify__toast-body.newToastBody {
    padding: 0 !important;
}

@include mobile {
    .Toastify__toast {
        margin-left: 16px !important;
        margin-right: 16px !important;
        margin-bottom: 70px !important;
    }
}

.Toastify__toast-body {
    padding: 6px 0px !important;
    margin: 0 !important;
    z-index: 99999999999 !important;
}

.imp-dialog.certification-danal.mobile {
    pointer-events: none;
}

.cropper-face {
    background-color: transparent !important;
}

.cropper-view-box {
    outline: 1px solid white !important;
}

.cropper-dashed {
    border: 0 solid white !important;
}

.cropper-crop-box > .dashed-h {
    border-bottom-width: 0.5px !important;
    border-top-width: 0.5px !important;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.cropper-crop-box > .dashed-v {
    border-left-width: 0.5px !important;
    border-right-width: 0.5px !important;
    opacity: 0;
    transition: opacity 0.5s ease;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 60px #fff inset !important;
    background-color: #fff !important;
    background-clip: content-box !important;
}


* {
    --fpx: 1.6px;
}

@media (max-width: 600px) {
    * {
        --fpx: 0.266vw;
    }
}
