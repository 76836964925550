@import "styles/color.module";
@import "styles/utils.module";

.root {
  position: relative;
  padding: 0 0 10px 0;
}

.title {
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: $typo3;
}

.couponList {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 12px;
  }
  padding: 8px 16px 0 16px;
}

.bottomButtonContainer {
  @extend .stickBottom;
  display: grid;
  margin-top: 12px;
  padding-top: 5px;
  grid-template-columns: 107fr 228fr;
  gap: 8px;  // gap is usable in display: grid.
}
