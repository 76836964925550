@import "styles/color.module";
@import "styles/utils.module";


.title {
  vertical-align: middle;
  margin-left: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.titleWrapper {
  line-height: 0;
  margin-bottom: 15px;
}

.container {
  padding: 24px 40px;
  background-color: $lightBackground;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  @extend .alignItemsCenter;
}


.contentContainer {
  max-width: 108px;
}



.horizontalBorder {}


@include desktop {
  .container {
    padding: 8px 20px;
    flex-direction: column;
  }

  .contentContainer {
    width: 100%;
    max-width: none;
    padding: 16px 0;
  }

  .horizontalBorder {
    width: 100%;
    border-top: 1px solid $border;
  }
}



.index {
  font-size: 13px;
  font-weight: bold;
  line-height: 140%;
  color: $typo2;
  vertical-align: middle;
}

.state {
  font-size: 16px;
  font-weight: bold;
  line-height: 130%;
  color: $typo3;
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
}

.content {
  font-size: 14px;
  font-weight: 500;
  color: $typo3;
  line-height: 130%;
  margin-top: 4px;
  display: block;
  white-space: wrap;
}

@include mobile {
  .index {
    font-size: 12px;
  }

  .state {
    font-size: 15px;
  }

  .content {
    font-size: 13px;
  }
}
