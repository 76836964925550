@import "styles/color.module";
@import "styles/utils.module";


.imagesContainer {
  display:flex;
  width:100%;
  max-width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 20px;
  overflow-y: hidden;
}

.imagesContainer::-webkit-scrollbar {
  display: none;
}

.photoUploadButtonContainer {
  flex: 0 0 76px;
  height: 76px;
  margin-right: 10px;
  border: 1px solid $brand;
  border-radius: 5px;
  @extend .flexCol;
  @extend .justifyContentCenter;
  @extend .alignItemsCenter;
  @extend .cursorPointer;
  @extend .relative;
}

.fileInput {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
}

.photoContainer {
  flex: 0 0 86px;
  @extend .noLineHeight;
  &:last-of-type {
    flex: 0 0 96px;
  }
}

.photoWrapper {
  width: 76px;
  height: 76px;
  position: relative;
  border-radius: 5px;
  margin-right: 10px;
  overflow: hidden;
  display: inline-block;
  @extend .cursorPointer;
}

.photoCounter {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.imagePickerCloseButton {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
}
